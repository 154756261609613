import { TextField, Button, Box } from '@mui/material';
import "../Style/Invorail.css"
import React, { useState } from 'react';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { postItemType } from '../../ApiHandler/apihandler';

export default function AddItemType() {
    const navigate = useNavigate();
    const token = localStorage.getItem('auth_token');

    const initialFormData = { name: "" };
    const [formdata, setFormData] = useState(initialFormData);

    const handleSaveItemType = async () => {
        try {
            console.log(formdata, "formdata--->>");
            const savedproduct = await postItemType(formdata);
            console.log(savedproduct, "savedproduct----");
            if (savedproduct.status === 200) {
                setFormData(initialFormData);
                swal("", "ItemType Added Successfully", "success");
                navigate('/itemtype');
            }
        } catch (error) {
            swal("", error.message, "danger");
        }
    };

    const handleDiscard = () => {
        setFormData(initialFormData);
    };

    return (
        <>
            <div className="container px-4">
                <div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <div><h5 style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>Item Type</h5></div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-md-6'>
                            <div>
                                <label htmlFor="clientName1" className="form-label">Item Type</label>
                                <TextField
                                    size="small"
                                    style={{ background: "#D0F0FF", width: "100%", borderRadius: "5px" }}
                                    className='border border-primary'
                                    onChange={(e) => setFormData({ ...formdata, name: e.target.value })}
                                    value={formdata.name}
                                    type='text'
                                />
                            </div>
                            <div className='d-flex justify-content-between mt-4'>
                                <div>
                                <button type="button" className="btn border border-primary" onClick={() => navigate(`/itemtype`)}>Preview</button>
                                </div>
                                <div>
                                <span>
                                    <button
                                        type="button"
                                        className="btn border border-primary"
                                        onClick={handleDiscard}
                                    >
                                        Discard
                                    </button>
                                </span>
                                <span style={{ marginLeft: "30px" }}>
                                    <button
                                        type="button"
                                        className="btn ml-2 text-white"
                                        style={{ background: "#19c3e6" }}
                                        onClick={handleSaveItemType}
                                    >
                                        Save
                                    </button>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
