import React, { useState, useEffect } from "react";
import PaymentNav from "./PaymentNav";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getPlanByid } from "../../../ApiHandler/apihandler";
import { useNavigate, useParams } from "react-router-dom";

const Customize = () => {


  const [landingPages, setLandingPages] = useState(0);
  const [pushNotifications, setPushNotifications] = useState(0);
  const [emailLimit, setEmailLimit] = useState(20000);
  const [alignment, setAlignment] = useState("monthly");
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null); 

  useEffect(() => {
    getPlanId();
  }, []);

  const getPlanId = async () => {
    try {
      const response = await getPlanByid(id);
      if (response.status === 200) {
        console.log(response.data, "new");
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleLandingPageChange = (delta) => {
    setLandingPages((prev) => Math.max(0, prev + delta));
  };

  const handlePushNotificationChange = (delta) => {
    setPushNotifications((prev) => Math.max(0, prev + delta));
  };

  const steps = [
    { label: "Choose a plan", path: "/choose-plan" },
    { label: "Customize", path: "/customize" },
    { label: "Checkout", path: "/checkout" },
  ];

  return (
    <>
      <div>
        {/* <PaymentNav steps={steps} currentStep={2} /> */}
        <div>
          <div className="row px-2">
            <div className="col-md-7">
              <div className="px-3 py-3">
                <h4>Customize your plan</h4>
                <div
                  className="card rounded mt-4"
                  style={{ height: "200px", boxShadow: "none" }}
                >
                  <div className="d-flex justify-content-between px-3 mt-4">
                    <p>Monthly number of emails and total number of contacts</p>
                    <p>{emailLimit} emails</p>
                  </div>
                  <div className="card-body">
                    <Slider
                      min={20}
                      defaultValue={20}
                      marks={{ 20: "20", 40: "40", 100: "100" }}
                      step={null}
                    />
                  </div>
                </div>
                <div className="additional-options mt-4">
                  <div className="option">
                    <span>Landing pages</span>
                    <div className="option-controls">
                      <button onClick={() => handleLandingPageChange(-1)}>
                        -
                      </button>
                      <span>{landingPages}</span>
                      <button onClick={() => handleLandingPageChange(1)}>
                        +
                      </button>
                    </div>
                  </div>
                  <div className="option">
                    <span>Push notifications</span>
                    <div className="option-controls">
                      <button
                        onClick={() => handlePushNotificationChange(-500)}
                      >
                        -
                      </button>
                      <span>{pushNotifications}</span>
                      <button onClick={() => handlePushNotificationChange(500)}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-5"
              style={{ backgroundColor: "gainsboro", height: "100vh" }}
            >
              <div className="px-3 mt-3">
                <h4>Purchase summary</h4>
                {data ? (
                  <div className="mt-5">
                    <div className=" d-flex justify-content-between">
                      <p className="mt-2 mb-0">{data.planName}</p>
                      <p className="mt-2 mb-0">{data.planPrice}/month</p>
                    </div>
                    <div className=" d-flex justify-content-between">
                      <p className="mt-2 mb-0">Sub Total</p>
                      <p className="mt-2 mb-0">Total: {data.planPrice}/month</p>
                    </div>
                    <hr
                      className="mt-4"
                      style={{
                        color: "black",
                        borderColor: "black",
                        borderWidth: "1px",
                      }}
                    />
                    <div className=" d-flex justify-content-end">
                      <p className="mt-2 mb-0">
                        <span style={{ fontSize: "38px" }}>{data.planPrice}</span> per month
                      </p>
                    </div>
                    <button className="btn btn-primary w-100 mt-5" onClick={()=>navigate(`/checkout/${data._id}`)}>
                      Proceed to checkout
                    </button>
                  </div>
                ) : (
                  <p>Loading plan details...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customize;
