import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import './stripe.css';
import visa from '../../../Images/visa.png';
import master from '../../../Images/master.png';
import amex from '../../../Images/amex.png';
import { useNavigate } from "react-router-dom";

const countries = {
  AU: {
    country: 'Australia', 
    fields: [
      { name: 'Address line 1', type: 'text', placeholder: 'Address line 1' },
      { name: 'Address line 2', type: 'text', placeholder: 'Address line 2' },
      { name: 'City', type: 'text', placeholder: 'City' },
      { name: 'Postal code', type: 'text', placeholder: '110034' },
      { name: 'State', type: 'text', placeholder: 'State' },
      { name: 'Phone', type: 'tel', placeholder: '+1 74104 10123' }
    ]
  },
  IN: {
    country: 'India',
    fields: [
      { name: 'Address line 1', type: 'text', placeholder: 'Address line 1' },
      { name: 'Address line 2', type: 'text', placeholder: 'Address line 2' },
      { name: 'City', type: 'text', placeholder: 'City' },
      { name: 'Postal code', type: 'text', placeholder: '110034' },
      { name: 'State', type: 'text', placeholder: 'State' },
      { name: 'Phone', type: 'tel', placeholder: '+91 74104 10123' }
    ] 
  }
};

function CheckoutForm({ data }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState('India');
  const [isBusiness, setIsBusiness] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscription] = useState([]);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const userID = localStorage.getItem('user_id');
  const plan = data;
  const priceId = plan?.priceId;

  console.log(plan, "plan in CheckoutForm");
  const [billingDetails, setBillingDetails] = useState({
    address1: '',
    address2: '',
    city: '',
    postalcode: '',
    state: '',
    phone: '',
    country:''
  });
  
  const [shippingDetails, setShippingDetails] = useState({
    address1: '', 
    address2: '',
    city: '',
    postalcode: '',
    state: '',
    phone: '',
    country:''
  });

  
  const handleBillingChange = (e) => { 
    setBillingDetails({
      ...billingDetails,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleShippingChange = (e) => {
    setShippingDetails({
      ...shippingDetails,
      [e.target.name]: e.target.value,
    });
  };      


  // const validateForm = () => {
  //   let formErrors = {};
  
  //   // Check billing details
  //   if (!billingCountry) {
  //     formErrors.billingCountry = "Billing country is required";
  //   }
  //   if (!billingDetails.address1) {
  //     formErrors.billingAddress = "Billing address is required";
  //   }
  //   // Add more checks for other billing fields as necessary...
  
  //   // Check shipping details if not same as billing
  //   if (!sameAsBilling) {
  //     if (!shippingCountry) {
  //       formErrors.shippingCountry = "Shipping country is required";
  //     }
  //     if (!shippingDetails.address1) {
  //       formErrors.shippingAddress = "Shipping address is required";
  //     }
  //     // Add more checks for other shipping fields as necessary...
  //   }
  
  //   setErrors(formErrors);
  //   return Object.keys(formErrors).length === 0; // Returns true if no errors
  // };
  
  

  const [billingCountry, setBillingCountry] = useState('');
  const [shippingCountry, setShippingCountry] = useState('');
  const [sameAsBilling, setSameAsBilling] = useState(false);

  const handleCountryChange = (event, setCountry) => {
    setCountry(event.target.value);
  };

  const renderFields = (countryCode,details, handleChange) => {
    if (!countryCode || !countries[countryCode]) return null;
    return countries[countryCode].fields.map((field, index) => (
      <div key={index} className="form-group">
        <input
        type={field.type}
        placeholder={field.placeholder}
        name={field.name.toLowerCase().replace(/ /g, '')}
        value={details[field.name.toLowerCase().replace(/ /g, '')] || ''}
        onChange={handleChange}
        className="form-control"
      />
      </div>
    ));
  };

  const calculateSubscriptionEndDate = (startDate, period) => {
    const date = new Date(startDate);
    switch (period) {
      case 'daily':
        date.setDate(date.getDate() + 1);
        break;
      case 'monthly':
        date.setMonth(date.getMonth() + 1);
        break;
      case 'quarterly':
        date.setMonth(date.getMonth() + 3);
        break;
      case 'yearly':
        date.setFullYear(date.getFullYear() + 1);
        break;
      default:
        break;
    }
    return date;
  };

  const createSubscription = async () => {
    console.log('Creating subscription');
    setLoading(true);
    try {
      // Create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name,
          email, 
          address: {
            line1: billingDetails.address1,
            line2: billingDetails.address2,
            city: billingDetails.city,
            postal_code: billingDetails.postalcode,
            state: billingDetails.state,
            country: billingCountry,
          },
        },
      });

      console.log(paymentMethod,"paymentMethod----->")

      // Call the backend to create a subscription
      const response = await fetch(`${process.env.REACT_APP_WEB_URL}api/v1/payment/create-subscription`, {
        method: "POST", 
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethod: paymentMethod?.paymentMethod?.id,
          name,
          email,
          priceId,
          billingDetails,
          shippingDetails: sameAsBilling ? billingDetails : shippingDetails,
        }),
      }).then((res) => res.json());

      console.log(response, "response-------->")

      const confirmPayment = await stripe?.confirmCardPayment(response.clientSecret);

      if (confirmPayment?.error) {
        alert(confirmPayment.error.message);
      } else {

        const startdate = new Date();
        const enddate = calculateSubscriptionEndDate(new Date(), plan.planDuration);
        console.log(startdate, enddate, "--------------enddateenddate")
        const verifyResponse = await fetch(`${process.env.REACT_APP_WEB_URL}api/v1/payment/verify-payment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionId: response.subscriptionId, planName: plan?.planName, amount: plan?.planPrice, plan_id: plan?._id, startdate: startdate, enddate: enddate, userID: userID, currency: plan?.currency

          }),
        }).then((res) => res.json());
        // alert("Success! Check your email for the invoice.");
        console.log(verifyResponse.success, "success------>")
        if (verifyResponse.success) {
          navigate('/success');
        } else {
          alert('Payment verification failed.');
        }


      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false); // Hide loader
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

 
  

  return (
    <>
      <div className="checkout-container">
        <div>
          <div className="subscription-details">
            <h4>Subscribe to <span style={{color:'red'}}>{plan?.planName}</span></h4>
            <h2 className="price">₹{plan?.planPrice}.00 per {plan?.planDuration}</h2>
            <div className="containers">
              <div className="plan-summary">
                <p><strong>Plus</strong></p>
                <p>InVideo AI Plus plan</p>
                <p>Billed annually</p>
              </div>
              <div>₹{plan?.planPrice}</div>
            </div>
             
            <div className="price-breakdown">
              <div className="breakdown-item">
                <p>Subtotal</p>
                <p>₹{plan?.planPrice}.00</p>
              </div>
              <div className="breakdown-item">
                <p>IGST (0% inclusive)</p>
                <p>₹{(plan?.planPrice * 0).toFixed(2)}</p>
              </div>
              <div className="breakdown-item total">
                <p>Total due today</p>
                <p>₹{plan?.planPrice}.00</p>
              </div>
            </div>
          </div>
          <div className="customer-form">
            <div className="billing-details">
              <h6><strong>Billing Details:</strong></h6> 
              <select value={billingCountry} onChange={(e) => handleCountryChange(e, setBillingCountry)} style={{width:'400px',padding:'7px',borderRadius:'5px'}}>
                <option value="">Choose a country...</option>
                {Object.keys(countries).map((code) => ( 
                  <option key={code} value={code}>
                    {countries[code].country}
                  </option>
                ))}
              </select>
              {renderFields(billingCountry, billingDetails, handleBillingChange)}
            </div> 
            <div className="shipping-details">
              <h6><strong>Shipping Details:</strong></h6> 
              <label>
                <input
                  type="checkbox"
                  checked={sameAsBilling} 
                  onChange={() => setSameAsBilling(!sameAsBilling)}
                /> Same as billing details
              </label><br></br> 

              {!sameAsBilling && (  
                <>  
                  <select value={shippingCountry} onChange={(e) => handleCountryChange(e, setShippingCountry)} style={{width:'400px',padding:'7px',borderRadius:'5px'}}>
                    <option value="">Choose a country...</option>
                   
                    {Object.keys(countries).map((code) => (
                      <option key={code} value={code}>
                        {countries[code].country}
                      </option>
                    ))}
                  </select>
                  {renderFields(shippingCountry, shippingDetails, handleShippingChange)}

                </>
              )}
            </div>
            {/* <button className="btn btn-primary">Add customer</button>
            <button className="btn btn-secondary">Cancel</button> */}
          </div>
        </div>
      
      
        <div className="payment-form">
          <div className="form-group">
            {/* <span className="card-element-icon">Email</span> */}
            <input
              placeholder="Email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
            /> 
          </div> 
          <div className="card-element-wrapper">
            <label><strong className="font-size-small">Card information</strong></label>
            <div className="card-input-container">
              <CardNumberElement options={cardElementOptions} className="card-element" />
              <div className="card-element-icons">
                <img src={visa} alt="Visa" className="card-icon" />
                <img src={master} alt="MasterCard" className="card-icon" />
                <img src={amex} alt="Amex" className="card-icon" />
              </div>

            </div>
            <div className="card-element-row">
              <CardExpiryElement options={cardElementOptions} className="card-element half-width" />
              <CardCvcElement options={cardElementOptions} className="card-element half-width" />
            </div>
          </div>
          <label><strong className="font-size-small">Price</strong></label>
          <input
            type="text"
            value={plan?.planPrice}
            disabled
            // onChange={(e) => setEmail(e.target.value)}
            className="form-input"
          />  
          <label><strong className="font-size-small">Cardholder name</strong></label>
          <input
            placeholder="Full name on card"
            type="text"
            value={name}  
            onChange={(e) => setName(e.target.value)}
            className="form-input"
          />
          <label><strong className="font-size-small">Country or region</strong></label>
          <select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="form-input"
          >
            <option value="India">India</option>
          </select>
          <div className="business-checkbox ">
            <input
              type="checkbox"
              checked={isBusiness}
              onChange={(e) => setIsBusiness(e.target.checked)}
            />
            <label className="mt-2">I'm purchasing as a business</label>
          </div>
          <button onClick={createSubscription} disabled={!stripe || loading} className="subscribe-button">
            {loading ? 'Processing...' : 'Subscribe'}
          </button>
          <p className="terms">
            By confirming your subscription, you allow Whitesheep Technology Pvt Ltd GST 27AACCW5313K1ZT
            to charge you for future payments in accordance with their terms. You can always cancel your subscription.
          </p>
        </div>

      </div>
    </>
  );
}

export default CheckoutForm;
