import React, { useState, useEffect } from "react";
import PaymentNav from "./PaymentNav";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { Button, TextField, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";
import { getPlanByid } from "../../../ApiHandler/apihandler";
import StripePayment from "./StripePayment";
import StripeForm from "./stripeform";


const Checkout = () => {

  const { id } = useParams();

  const [alignment, setAlignment] = useState("monthly");
  const [showInput, setShowInput] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [data, setData] = useState(null)


  useEffect(() => {
    planId()
  }, []);


  const planId = async () => {

    const res = await getPlanByid(id);
    if (res.status === 200) {
      console.log(res.data, "checkoutdata")
      setData(res.data.data)
    }
    else {
      console.log("error--------")
    }
  }

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const handleButtonClick = () => {
    setShowInput(!showInput);
  };

  const handleApply = () => {
    // Add logic to apply the coupon code
    console.log("Coupon Code Applied:", couponCode);
  };

  const handleCancel = () => {
    setShowInput(false);
    setCouponCode("");
  };

  const steps = [
    { label: "Choose a plan", path: "/choose-plan" },
    // { label: "Customize", path: "/customize" },
    { label: "Checkout", path: "" },
  ];

  return (
    <>
      <PaymentNav steps={steps} currentStep={3} />
      <div >
        <StripeForm data={data}/>
      </div>
    </>
  );
};

export default Checkout;
