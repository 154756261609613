import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Table, Button, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import PaymentForm from './invoicepayment';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReplyIcon from '@mui/icons-material/Reply';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
const stripe = loadStripe('pk_test_51PVdhuLwXNdguAahbR3CdwoeSpZoJDyIJTGPrJpjsRV6TEUTwEpA04OVeo3QUav2v8kx63PFuVYrhgH3wnFpgn9p00Nd1OloQy');




const Downloadinvoice = () => {
    const [invoice, setInvoice] = useState(null);
    const { id } = useParams();
    const pdf = useRef();
    const [showModal, setShowModal] = useState(false);
    const [copied, setCopied] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = `/invoices/${id}`;
    


    useEffect(() => {
        invoicedetails();
    }, []);

    const invoicedetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/invoice/getinvoiceid/${id}`);
            if (response.status === 200) {
                setInvoice(response.data.data);
                console.log(response.data.data, "response--------->")
            }
        } catch (error) {
            console.error('Error fetching invoice details:', error);
        }
    };

    const downloadInvoice = () => {
        const input = pdf.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');

            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('invoice.pdf');
        });
    };

    if (!invoice) {
        return <div>Loading...</div>;
    }

    const handlePayNowClick = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const subtotal = invoice.invoice_product_items.reduce((total, item) => total + item.amount, 0);
    const taxAmount = invoice.taxAmount || 0;
    const adjustment = invoice.adjustment || 0;
    const totalAmount = subtotal + taxAmount + adjustment;


    const copyToClipboard = () => {
        const textToCopy = window.location.href;
      
        navigator.clipboard.writeText(textToCopy)
          .then(() => {
            // alert('Link copied to clipboard!');
            setCopied(true);
            setTimeout(()=>setCopied(false),4000)
          })
          .catch((err) => {
            console.error('Failed to copy: ', err);
          });
      };

    return (
        <Container className="px-4 py-4 mt-4 border rounded bg-light shadow">
            <div className="d-flex justify-content-between align-items-center mb-4">
            { location.pathname === currentPath ? <h5 style={{ fontWeight: "bold", fontFamily: "sans-serif", cursor:'pointer' }} onClick={()=>navigate(`/invoice/${id}`)}>  <ArrowBackIosIcon className='mb-1'/> Invoice</h5>:<h5 style={{ fontWeight: "bold", fontFamily: "sans-serif"}}>Invoice</h5>}
             {location.pathname === currentPath ? <span className='mb-1' style={{fontFamily:'sans-serif',fontSize:'small',fontWeight:'bold'}}>Copy: {window.location.href} <span>{copied ? <LibraryAddCheckIcon/>:<ContentCopyIcon className='ml-1' onClick={copyToClipboard} style={{cursor:'pointer'}}/>}</span></span>:''}   
                <div className='d-flex gap-2'>
                    {location.pathname !== currentPath && (
                        <Button variant="primary" onClick={() => navigate(`/invoices/${id}`)}>
                            <ReplyIcon/>
                        </Button>
                    )}
                    {invoice?.payment_status === 'Unpaid' ? <Button variant="primary" onClick={handlePayNowClick}>Pay Now</Button> : ''}
                    <Button variant="primary" onClick={() => downloadInvoice()}>Download</Button>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Modal content goes here */}
                    <Elements stripe={stripe}>
                        <PaymentForm Id={id} invoicedetails={invoicedetails} handleClose={handleClose} email={invoice?.customer?.email} totalAmount={invoice?.totalAmount} />
                    </Elements>

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary">
                        Confirm Payment
                    </Button>
                </Modal.Footer> */}
            </Modal>

            <div ref={pdf} className=' mt-4 bg-white' style={{ padding: "30px" }}>
                <div className="d-flex justify-content-between align-items-start mb-3">
                    <div>
                        <h6 className="font-weight-bold text-uppercase">Billing</h6>
                        <div>
                            <strong>Name:</strong> {invoice.customer.first_name}<br />
                            <strong>Email:</strong> {invoice.customer.email}<br />
                            <strong>Company:</strong> {invoice.customer.company}<br />
                            <strong>Mobile:</strong> {invoice.customer.phone}<br />
                            <strong>Address:</strong> {invoice?.billing_address[0]?.line_1}, {invoice?.billing_address[0]?.line_2},<br /> {invoice?.billing_address[0]?.city}, {invoice?.billing_address[0]?.state}, {invoice?.billing_address[0]?.zipcode}, {invoice?.billing_address[0]?.country}<br />
                        </div> 
                    </div>
                    <div>
                        <h6 className="font-weight-bold text-uppercase">Shipping</h6>
                        <strong>Address:</strong> {invoice?.shipping_addresses[0]?.addressline1}, {invoice?.shipping_addresses[0]?.addressline2},<br /> {invoice?.shipping_addresses[0]?.city}, {invoice?.shipping_addresses[0]?.state}, {invoice?.shipping_addresses[0]?.postalcode}, {invoice?.shipping_addresses[0]?.country}<br />
                       
                        <div>
                        </div>
                    </div>
                </div>
                <br />
                <Row className="mb-4">
                    <Col md={8}>
                        {/* <p style={{ fontSize: "15px" }}>
                            Hello, <span className='text-danger'>{invoice.customer.name}</span><br />
                            Thank you for shopping from our store and for your order.
                        </p> */}
                    </Col>
                    <Col md={4} className="text-right">
                        <div><strong>Invoice No.:</strong> #RIXO669B5FD5BEC35</div>
                        <div className='mt-2'> <strong className='mt-2'>Date:</strong> {format(new Date(invoice.invoice_date), 'dd/MM/yyyy')} </div>
                        <div className='mt-2'><strong>Status:</strong> <Button variant={invoice.payment_status === 'Paid' ? 'success' : 'danger'}
                            size="sm" >{invoice.payment_status}</Button></div>
                    </Col>
                </Row>

                <Table striped bordered hover className="mt-4">
                    <thead className="thead-dark">
                        <tr>
                            <th>Item</th>
                            <th>Rate</th>
                            <th>Qty</th>
                            <th>Tax</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice.invoice_product_items.map((item, index) => (
                            <tr key={index}>
                                <td>{item.itemName}</td>
                                <td>{item.rate}</td>
                                <td>{item.quantity}</td>
                                <td>{item.tax}%</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Row className="justify-content-end">
                    <Col md={4}>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <td className='fw-bold'>Sub Total</td>
                                    <td>{subtotal}</td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td>{invoice?.taxAmount}</td>
                                </tr>
                                <tr>
                                    <td>Adjustment</td>
                                    <td>{invoice?.adjustment}</td>
                                </tr>
                                <tr>
                                    <td className='fw-bold'>Total</td>
                                    <td>{invoice?.totalAmount}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <footer className="text-center mt-4">
                    <p style={{ fontSize: "12px" }}>Thank you for your business! If you have any questions, feel free to contact us at bhabesh@rixosys.com.</p>
                </footer>
            </div>
        </Container>
    );
};

export default Downloadinvoice;
