import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import '../Premiums/stripe.css';
import visa from '../../../Images/visa.png';
import master from '../../../Images/master.png';
import amex from '../../../Images/amex.png';
import { useNavigate } from "react-router-dom";

function PaymentForm({ Id,invoicedetails ,handleClose,email,totalAmount}) {
  const [name, setName] = useState("");
  const [emails, setEmail] = useState(email);
  const [loading, setLoading] = useState(false);
  const [amount , setAmount] = useState(totalAmount)
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const cardElementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  // const handleSubmit = async () => {
  //   setLoading(true);
  //   try {
  //     const paymentMethod = await stripe?.createPaymentMethod({
  //       type: "card",
  //       card: elements?.getElement(CardNumberElement),
  //       billing_details: {
  //         name,
  //         email,
  //       },
  //     });

  //     // Handle the rest of the payment process as needed

  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Create a payment method
      const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name,
          email,
          // address: {
          //   line1: billingDetails.address1,
          //   line2: billingDetails.address2,
          //   city: billingDetails.city,
          //   postal_code: billingDetails.postalcode,
          //   state: billingDetails.state,
          //   country: billingCountry,
          // },
        },
      });
  
      if (paymentMethodError) {
        alert(paymentMethodError.message);
        return;
      }
  
      // Call backend to create payment intent
      const response = await fetch(`${process.env.REACT_APP_WEB_URL}api/v1/payment/create-payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethod: paymentMethod.id,
          amount, // replace with actual amount
          currency: "usd",
          name,
          email,
          Id
        }),
      }).then((res) => res.json());
  
      // Confirm the payment intent with the payment method
      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(response.clientSecret, {
        payment_method: paymentMethod.id,
      });
  
      if (confirmError) {
        alert(confirmError.message);
      } else {
        console.log("Payment successful:", paymentIntent);
        // navigate('/success-payment');
        if(paymentIntent.status === 'succeeded'){
          await handleClose();
          await invoicedetails();

        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  


  return (
    <div className="payment-form">
      <div className="form-group">
        <input
          placeholder="Email"
          type="text"
          value={emails}
          // onChange={(e) => setEmail(e.target.value)}
          disabled
          className="form-input"
        />
      </div>
      <div className="card-element-wrapper">
        <label><strong className="font-size-small">Card information</strong></label>
        <div className="card-input-container">
          <CardNumberElement options={cardElementOptions} className="card-element" />
          <div className="card-element-icons">
            <img src={visa} alt="Visa" className="card-icon" />
            <img src={master} alt="MasterCard" className="card-icon" />
            <img src={amex} alt="Amex" className="card-icon" />
          </div>
        </div>
        <div className="card-element-row">
          <CardExpiryElement options={cardElementOptions} className="card-element half-width" />
          <CardCvcElement options={cardElementOptions} className="card-element half-width" />
        </div>
      </div>
      <label><strong className="font-size-small">Cardholder name</strong></label>
      <input
        placeholder="Full name on card"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="form-input"
      />
      <label><strong className="font-size-small">Amount</strong></label>
      <input
        placeholder="Payment Amount"
        type="text"
        value={amount}
        // onChange={(e) => setAmount(e.target.value)}
        disabled
        className="form-input"
      />
      <button onClick={handleSubmit} disabled={!stripe || loading} className="subscribe-button">
        {loading ? 'Processing...' : 'Confirm Payment'}
      </button>
    </div>
  );
}

export default PaymentForm;
