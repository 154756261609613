import React from 'react';
import { Modal, TextField, Button } from '@mui/material';

export default function EditClientModal({ open, handleClose, client, handleChange, handleSave }) {
    if (!client) return null;

    const handleFieldChange = (e) => {
        handleChange(e.target.name, e.target.value);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <div style={{ padding: '20px', background: 'white', margin: '100px auto', maxWidth: '600px' }}>
                <h2>Edit Client</h2>
                <TextField
                    name="name"
                    label="Name"
                    value={client.name}
                    onChange={handleFieldChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    name="email"
                    label="Email"
                    value={client.email}
                    onChange={handleFieldChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    name="company"
                    label="Company"
                    value={client.company}
                    onChange={handleFieldChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    name="business_type"
                    label="Business Type"
                    value={client.business_type}
                    onChange={handleFieldChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    name="phone"
                    label="Phone"
                    value={client.phone}
                    onChange={handleFieldChange}
                    fullWidth
                    margin="normal"
                />
                <Button variant="contained" color="primary" onClick={handleSave} >Update</Button>
                <Button variant="outlined" color="secondary" onClick={handleClose} style={{marginLeft:"10px"}}>Cancel</Button>
            </div>
        </Modal>
    );
}
