import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { getClient, updateClient, deleteClient } from '../../../ApiHandler/apihandler';
import EditClientModal from './EditClientModal';
import EmptyDesign from '../../EmptyDesign/EmptyDesign';
import Swal from 'sweetalert2';


export default function ClientPage() {
    const navigate = useNavigate();
    const [clients, setAllClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    useEffect(() => {
        getclients();
    }, []);

    const getclients = async () => {
        try {
            const data = await getClient();
            if (data.status === 200) {
                setAllClients(data.data.data);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteClient = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#19c3e6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            });
    
            if (result.isConfirmed) { 
                const deleteResponse = await deleteClient(id);
                if (deleteResponse.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your client data has been deleted.",
                        icon: "success",
                        confirmButtonColor: "#19c3e6"
                    });
                    getclients();
                }
            }
        } catch (error) {
            console.error("Error deleting plan:", error);
        }
    };

    const handleEditClient = (client) => {
        setSelectedClient(client);
        setIsModalOpen(true);
    };

    const handleChange = (field, value) => {
        setSelectedClient({ ...selectedClient, [field]: value });
    };

    const handleSaveClient = async () => {
        try {
            const data = {
                first_name: selectedClient.first_name,
                email: selectedClient.email,
                company: selectedClient.company,
                phone: selectedClient.phone,
                business_type: selectedClient.business_type
            };
            const response = await updateClient(selectedClient._id, data);
            if (response.status === 200) {
                swal('Client updated successfully', '', 'success');
                getclients();
                setIsModalOpen(false);
            }
        } catch (error) {
            swal('Error', 'Client could not be updated', 'error');
        }
    };
               
    return (
        <>
            <div className="container px-2">
                {!loading && clients.length > 0 && (
                    <div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <div><h5 style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>Clients</h5></div>
                            <div>
                                <button type="button" className="btn text-white" style={{ background: "#19c3e6" }} onClick={() => navigate(`/add-client`)}>
                                    Add Clients 
                                </button>
                            </div>        
                        </div> 
                    </div>
                )}
                {loading ? (
                    <div className='d-flex align-items-center justify-content-center' style={{ marginTop: "150px" }}>
                        <CircularProgress />
                    </div>
                ) : ( 
                    clients.length > 0 ? (
                        <div>
                            <Card className='mt-4' style={{ border: "2px solid #01ACFF" }}>
                                <div className='d-flex flex-wrap align-items-center mt-2 ' style={{ gap: "63%" }}>
                                    <div><h6 style={{ fontWeight: "bold", fontFamily: "sans-serif", marginTop: "10px", marginLeft: "10px" }}>Clients</h6></div>
                                </div>
                                <div className='maintable'>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                          
                                                    <TableCell align="left">Name</TableCell>
                                                    <TableCell align="left">Email</TableCell>
                                                    <TableCell align="left">Company</TableCell>
                                                    <TableCell align="left">Business Type</TableCell>
                                                    <TableCell align="left">Contact No</TableCell>
                                                    <TableCell align="left">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {clients.map((row) => (
                                                    <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell align="left">{row.first_name}</TableCell>
                                                        <TableCell align="left">{row.email}</TableCell>
                                                        <TableCell align="left">{row.company}</TableCell>
                                                        <TableCell align="left">{row.business_type}</TableCell>
                                                        <TableCell align="left">{row.phone}</TableCell>
                                                        <TableCell align="left">
                                                            <span onClick={() => navigate(`/clientdetailes/${row._id}`)}><RemoveRedEyeIcon  style={{ cursor: 'pointer' }} /></span>
                                                            <span onClick={() => handleDeleteClient(row._id)} style={{ cursor: "pointer" }}><DeleteIcon className='text-danger' /></span>
                                                            <span onClick={() => handleEditClient(row)} style={{ cursor: "pointer" }}><EditNoteIcon /></span>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Card>
                        </div>
                    ) : (
                    <EmptyDesign />
                    )
                )}

                <EditClientModal
                    open={isModalOpen}
                    handleClose={() => setIsModalOpen(false)}
                    client={selectedClient}
                    handleChange={handleChange}
                    handleSave={handleSaveClient}
                />
            </div>
        </>
    );
}
