

import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useNavigate } from 'react-router-dom';
import { getAllRecurringInvoice, deleteRecurringInvoice,cancelRecurringSubscription } from '../../../ApiHandler/apihandler';
import moment from 'moment';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from 'react-bootstrap';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';





export default function RecuringInvoices() {
  const navigate = useNavigate();
  const [invoiceData, setInvoicedata] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Getinvoice();
  }, []);

  const Getinvoice = async () => {
    const response = await getAllRecurringInvoice();

    if (response.status === 200) {
      console.log(response.data, "get all invoice data");
      setInvoicedata(response.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

 

  const handleCancelSubscription = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure to cancel your subscription?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#19c3e6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel it!"
      });
      if (result.isConfirmed) {
        // const deleteResponse = await deleteRecurringInvoice(id);
        const response = await cancelRecurringSubscription(id)
        if (response.status === 200) {
          Swal.fire({
            title: "Canceled!",
            text: "Your subscription  has been canceled.",
            icon: "success",
            confirmButtonColor: "#19c3e6"
          });
          Getinvoice();
        }
      }



    } catch (error) {
      console.error("Error deleting invoice:", error);
    }
  };






  return (
    <>
      <div className="container px-4">
        <div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div><h5 style={{ fontWeight: "bold", fontFamily: "sans-serif" }}> Recurring Invoices</h5></div>
            <div>
              <button type="button" className="btn text-white" style={{ background: "#19c3e6" }} onClick={() => navigate("/create-recurring-invoice")}>Create Invoice</button>
            </div>
          </div>
        </div>

        <div>

          {loading ? (
            <div className="d-flex align-items-center justify-content-center" style={{ marginTop: '150px' }}><CircularProgress /></div>) : (

            <Card className=' mt-2'>
              <div className='maintable'>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align='left'>ID</TableCell> */}
                      <TableCell align="left">Customer</TableCell>
                      <TableCell align="left">Amount</TableCell>
                      <TableCell align="left">Create Date</TableCell>
                      <TableCell align="left">Due Date</TableCell>
                      <TableCell align="left">Actions</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData.map((row, index) => (
                        row.recurringInvoice === 'yes' ? 
                      <TableRow
                        key={row._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {/* <TableCell component="th" scope="row">{index + 101}</TableCell> */}
                        
                        <TableCell align="left">{row?.customer?.first_name}</TableCell>
                        <TableCell align="left">{row.totalAmount}</TableCell>
                        <TableCell align="left">{moment(row.invoice_date).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="left">{moment(row.due_date).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="left" style={{ cursor: "pointer" }}> <span onClick={() => navigate(`/recurring-invoice/${row._id}`)}><RemoveRedEyeIcon /></span>
                          {row.stripe_subscriptionId ? <span onClick={() => handleCancelSubscription(row?.stripe_subscriptionId)} style={{color:'red',margin:'5px'}}><UnsubscribeIcon/></span>: row?.subscription_status?<span style={{color:'red',marginLeft:'5px'}}>{row?.subscription_status}</span>:<span style={{color:'green',marginLeft:'5px'}}>No subscription</span>}
                        </TableCell>
                      </TableRow> :''
                    ))}
                  </TableBody>

                </Table>
              </div>
            </Card>


          )}

        </div>

      </div>




    </>
  );
}

