import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, Select, MenuItem, AppBar, Toolbar, Typography, Button } from '@mui/material'; // Importing Typography here
import TextareaAutosize from '@mui/material/TextareaAutosize';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './invoice.css'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { addMonths, addWeeks, addYears } from 'date-fns';



// @mui
import InvoiceCard from "./invoicecard"
import InvoiceNote from './invoicenote';
import { createRecurringInvoice } from '../../../ApiHandler/apihandler';
//--------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '10%',
    left: '62%',
    transform: 'translate(-50%, 0%)',
    width: '79%',
    bgcolor: 'background.paper',
    outline: 'none',
    borderRadius: '15px',
    p: 4,
};


const countries = {
    // AU: {
    //   country: 'Australia',
    //   fields: [
    //     { name: 'Address line 1', type: 'text', placeholder: 'Address line 1' },
    //     { name: 'Address line 2', type: 'text', placeholder: 'Address line 2' },
    //     { name: 'City', type: 'text', placeholder: 'City' },
    //     { name: 'Postal code', type: 'text', placeholder: '110034' },
    //     { name: 'State', type: 'text', placeholder: 'State' },
    //     { name: 'Phone', type: 'tel', placeholder: '+1 74104 10123' }
    //   ]
    // },
    IN: {
        country: 'India',
        fields: [
            { name: 'Address line 1', type: 'text', placeholder: 'Address line 1' },
            { name: 'Address line 2', type: 'text', placeholder: 'Address line 2' },
            { name: 'City', type: 'text', placeholder: 'City' },
            { name: 'Postal code', type: 'text', placeholder: '110034' },
            { name: 'State', type: 'text', placeholder: 'State' },
            { name: 'Phone', type: 'tel', placeholder: '+91 74104 10123' }
        ]
    }
};



function AddRecurringInvoice() {

    const navigate = useNavigate();


    const [user, setUser] = useState({});
    const [currency, setCurrency] = React.useState('')
    const [customer, setCustomer] = useState("");
    // ---------------------------------------------------Date&&Calender---------------->
    const initialDate = dayjs();
    const dueDate = dayjs(initialDate).add(3, 'day');
    const [invoicedate, setInvoicedate] = useState(initialDate);
    const [duedate, setDuedate] = useState(dueDate);
    const [recurringinvoice, setRecurringInvoice] = React.useState('yes');
    const [recurringPeriod, setRecurringPeriod] = useState('')
    const [discount, setDiscount] = React.useState('');
    const [adminNote, setAdminNote] = useState("")
    const [emptycard, setEmptycard] = useState("")
    const [shippingCountry, setShippingCountry] = useState('');

    const getCurrentDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    // const [startDate, setStartDate] = useState(getCurrentDate());
    // const [endDate, setEndDate] = useState('');
    const [isNeverExpiresChecked, setIsNeverExpiresChecked] = useState(false);
    const [repeatInterval, setRepeatInterval] = useState('week');


    // Step 2: Handle the checkbox change event

    // const handleStartDateChange = (e) => {
    //     setStartDate(e.target.value);
    // };

    // const handleEndDateChange = (e) => {
    //     setEndDate(e.target.value);
    // };

    // const handleCheckboxChange = (e) => {
    //     setIsNeverExpiresChecked(e.target.checked);
    //     if (e.target.checked) {
    //         setEndDate('Lifetime'); // Set to "Lifetime" when Never Expires is checked
    //     } else {
    //         setEndDate(''); // Reset Ends On date when checkbox is unchecked
    //     }
    // };

    // const handleRepeatIntervalChange = (e) => {
    //     setRepeatInterval(e.target.value);
    //     if (e.target.value === 'Month') {
    //       setEndDate(addMonths(startDate, 1));
    //     }
    //     // Additional logic for other intervals like 'Week' can be added here
    // };



    const handleCountryChange = (event, setCountry) => {
        setCountry(event.target.value);
    };

    const renderFields = (countryCode, details, handleChange) => {
        if (!countryCode || !countries[countryCode]) return null;
        return countries[countryCode].fields.map((field, index) => (
            <div key={index} className="form-group">
                <input
                    type={field.type}
                    placeholder={field.placeholder}
                    name={field.name.toLowerCase().replace(/ /g, '')}
                    value={details[field.name.toLowerCase().replace(/ /g, '')] || ''}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
        ));
    };


    const [shippingDetails, setShippingDetails] = useState({
        addressline1: '',
        addressline2: '',
        city: '',
        postalcode: '',
        state: '',
        phone: '',
        country: ''
    });


    const handleShippingChange = (e) => {
        setShippingDetails({
            ...shippingDetails,
            [e.target.name]: e.target.value,
        });
    };




    const handleChangedate = (date) => {
        setInvoicedate(date);
    }
    console.log(invoicedate, "invoicedate--------")
    const handleChangeDuedate = (date) => {
        setDuedate(date);
    }

    const handleChangerecurringPeriod = (event) => {
        const value = event.target.value;
        setRecurringPeriod(value);
        setRepeatInterval(value);

        // Ensure startDate is a valid dayjs object before performing date operations
        const start = dayjs(startDate);

        if (!start.isValid()) {
            console.error("Invalid start date");
            return;
        }

        // Adjust the end date based on the selected period using dayjs
        if (value === 'day') {
            setEndDate(start.add(1, 'day'))
        } else if (value === 'week') {
            setEndDate(start.add(1, 'week')); // Add 1 week
        } else if (value === '3 months') {
            setEndDate(start.add(3, 'month')); // Add 3 months
        } else if (value === 'year') {
            setEndDate(start.add(1, 'year')); // Add 1 year
        } else if (value === 'month') {
            setEndDate(start.add(1, 'month')); // Add 1 month
        }
        console.log(recurringinvoice);
    };


    const handleChangeDiscount = (event) => {
        const newdiscount = event.target.value;
        setDiscount(newdiscount);
    };

    const handleAdminNoteChange = (event) => {
        setAdminNote(event.target.value);
    };

    const handleCustomer = async (event) => {
        const customerId = event.target.value;
        setCustomer(customerId);

        try {
            const response = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/client/getclient/${customerId}`, {
                headers: {
                    'x-auth-token': token
                }
            });

            const filteredData = customerdata.find(elem => elem?._id === customerId);
            console.log(filteredData, 'Filtered Customer Data');
            console.log(response.data.data, "Fetched Data");

            setUser(response.data.data);
        } catch (error) {
            console.error('Error fetching customer data:', error);
        }
    };

    const handleCurrency = (event) => {
        setCurrency(event.target.value);
    };


    const handleSaveInvoice = async () => {
        try {
            const invoiceData = {
                customer: customer,
                invoice_currency: currency,
                invoice_date: invoicedate,
                due_date: duedate,
                recurringInvoice: recurringinvoice,
                recurringPeriod: recurringPeriod,
                recurringStartDate: startDate,
                recurringEndDate: endDate,
                discountType: discount,
                adminNote: adminNote,
                adjustment: adjustment,
                taxAmount: totalTax,
                totalAmount: calculateTotalAmount(),
                invoice_product_items: cards,
                billing_address: user?.billing_addresses,
                shipping_addresses: shippingDetails
                // add other necessary data fields here
            };

            if (recurringinvoice === 'yes') {
                invoiceData.recurringStartDate = startDate;
                invoiceData.recurringEndDate = endDate;
                invoiceData.repeatInterval = repeatInterval; // assuming this is the field for 'Repeat Every'
            }

            console.log(invoiceData, "invoice data------")

            const response = await createRecurringInvoice(invoiceData);
            navigate(`/recurring-invoices`)
            console.log('Invoice created successfully:', response.data);
        } catch (error) {
            console.error('Error creating invoice:', error);
        }
    };


    const handleChangeProduct = async (event) => {
        setProduct(event.target.value);
        console.log(event.target.value, "----product")
        const data = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/product/getProduct/${event.target.value}`, {
            headers: {
                'auth-token': token
            }
        });

        console.log(data.data.data, "data---")
        const product = data.data.data;
        setInput({ ...input, itemName: product?.name, itemDescription: product?.description, quantity: product?.quantity, tax: product?.tax, rate: product?.unitPrice })
    };





    // --------------------------------------API Handle----------->
    const [customerdata, setCustomerdata] = useState([]);
    const [productData, setProductData] = useState([])
    const [product, setProduct] = useState('')
    const token = localStorage.getItem('auth_token');

    useEffect(() => {
        getCaustomer();
        getProduct();// This will log the updated value
    }, []);


    const getCaustomer = async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/client/getclient`, {
                headers: {
                    'x-auth-token': token
                }
            });
            console.log(data);
            setCustomerdata(data.data.data);

        } catch (error) {
            console.log(error)
        }
    }




    const getProduct = async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/product/getProduct`, {
                headers: {
                    'x-auth-token': token
                }
            });
            console.log(data, "----data---getproduct");
            setProductData(data.data.data);

        } catch (error) {
            console.log(error)
        }
    }


    console.log("searchhhhhhhhhhhhhhh", customerdata);

    const [cards, setCards] = useState([]);
    const [notes, setNotes] = useState([]);

    // --------------------------------------CTRL Z------------------------------------->

    const newCard = {
        itemName: '',
        itemDescription: '',
        quantity: 1,
        rate: 0,
        tax: 0,
        amount: 0
    };

    const newNote = {
        title: '',
        description: ''
    }

    const [noteInput, setNoteInput] = useState(newNote);

    const [input, setInput] = useState(newCard);
    console.log(input, "manas----")

    const addCard = () => {
        setCards([...cards, input]);
        setEmptycard(input)
        setTimeout(() => {
            return setInput(newCard)
        }, 0)
    };

    console.log(emptycard, "ashish ----------")




    const handleChangev15 = (event) => {
        const updatedItemName = event.target.value;
        setInput({ ...input, itemName: updatedItemName });
    };

    const handleChangev16 = (event) => {
        setInput({ ...input, itemDescription: event.target.value })
    }


    const handleChangev6 = (event) => {
        setInput({ ...input, quantity: event.target.value });
    };

    const handleChangev7 = (event) => {
        setInput({ ...input, rate: event.target.value });
    };

    const handleChangev8 = (event) => {
        setInput({ ...input, tax: event.target.value });
    }



    // ----------------------------------------------------------------------------------->
    console.log(cards, 'array data')
    const addNotes = () => {
        setNotes([...notes, noteInput])
        setNoteInput(newNote);
    };


    function deleteHandlerCard(index) {
        console.log('Index to delete:', index);

        console.log('Before deletion - cards:', cards);
        const updatedCards = cards.filter((_, i) => i !== index);
        console.log('Updated cards:', updatedCards);

        // Recalculate totals after deletion
        const newTotalRates = calculateTotalRates(updatedCards);
        const newTotalTax = calculateTotalTaxs(updatedCards);

        setCards(updatedCards);
        setTotalRates(newTotalRates);
        setTotalTax(newTotalTax);

        console.log('After deletion - cards:', updatedCards);
    }

    function deleteHandlerNote(index) {
        console.log("index============", index);
        console.log("noters============", notes);
        const updatedNotes = notes.filter((ele, i) => i !== index);
        setNotes(updatedNotes);
    }


    // ----------------------------------------------------------------------------------->
    // State to store total amounts
    const [adjustment, setAdjustment] = useState(0);
    const [totalRates, setTotalRates] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [discont, setDiscont] = useState(0);

    // Function to handle card data change and recalculate totals
    const handleCardDataChange = (index, field, value) => {
        const updatedCards = [...cards];
        updatedCards[index][field] = value;

        // Update state to reflect changes in the cards array
        setCards(updatedCards);

        // Recalculate totals after the update
        const newTotalRates = calculateTotalRates(updatedCards);
        const newTotalTax = calculateTotalTaxs(updatedCards);
        setTotalRates(newTotalRates);
        setTotalTax(newTotalTax);
    };

    const handleNoteDataChange = (index, field, value) => {
        const updatedNotes = [...notes];
        updatedNotes[index][field] = value;
        setNotes(updatedNotes);
    }


    const handleChangev11 = (event) => {
        const adjustmnt = event.target.value;
        setAdjustment(Number(adjustmnt));
    }
    console.log(adjustment, typeof (adjustment))

    const calculateTotalRates = (cards) => {
        return cards.reduce((total, card) => total + card.rate * card.quantity, 0);
    };


    // Function to calculate the total tax
    const calculateTotalTaxs = (cards) => {
        return cards.reduce((total, card) => total + (card.tax * card.rate * card.quantity / 100), 0);
    };


    const [distype, setDistype] = useState('flat');
    const [inputvalue, setInputvalue] = useState(0)



    const handleChangev13 = (e) => {
        const newdiscont = e.target.value;
        setInputvalue(newdiscont);

        if (distype === 'percentage') {
            const parsedDiscount = parseFloat(newdiscont);
            if (!Number.isNaN(parsedDiscount)) {
                if (discount === 'Before Tax') {
                    const percentdiscount = (parseFloat(totalRates) * parsedDiscount) / 100;
                    setDiscont(percentdiscount);
                } else if (discount === 'After Tax') {
                    const percentsdiscount = (totalRates + totalTax) ? (totalRates + totalTax) * parsedDiscount / 100 : 0;
                    setDiscont(percentsdiscount);
                } else {
                    setDiscont(parsedDiscount);
                }
            } else {
                setDiscont(0);
            }
        } else {
            setDiscont(parseFloat(newdiscont));
        }
    };



    const handleChangev14 = (e) => {
        const newdistype = e.target.value;
        setDistype(newdistype);

        if (newdistype === 'flat') {
            setDiscont(inputvalue); // Use the current input value for flat
        } else if (newdistype === 'percentage') {
            // Convert the current input value to a percentage value
            const parsedInputValue = parseFloat(inputvalue);
            if (!Number.isNaN(parsedInputValue)) {
                if (discount === 'Before Tax') {
                    const percentValue = (parsedInputValue * totalRates) / 100;
                    setDiscont(percentValue);
                } else if (discount === 'After Tax') {
                    const percentValue = (totalRates + totalTax) ? (parsedInputValue * (totalRates + totalTax)) / 100 : 0;
                    setDiscont(percentValue);
                } else {
                    setDiscont(0);
                }
            } else {
                setDiscont(0);
            }
        }
    };
    const calculateDiscount = (inputValue, taxMode, totalRates, totalTax, distype) => {
        const parsedDiscount = parseFloat(inputValue);

        if (!Number.isNaN(parsedDiscount)) {
            if (taxMode === 'Before Tax') {
                return distype === 'percentage' ? (totalRates * parsedDiscount) / 100 : parsedDiscount;
            } else if (taxMode === 'After Tax') {
                return distype === 'percentage' ? ((totalRates + totalTax) * parsedDiscount) / 100 : parsedDiscount;
            }
        }

        return 0;
    };


    useEffect(() => {
        console.log('totalRates', totalRates);
        console.log('totalTax', totalTax);
        console.log('inputvalue', inputvalue);
        console.log('taxMode', discount);
        console.log('distype', distype);
        // Calculate the discount based on the selected tax mode and input value

        const newDiscount = calculateDiscount(inputvalue, discount, totalRates, totalTax, distype);
        setDiscont(newDiscount);
    }, [totalRates, totalTax, inputvalue, discount, distype]);

    // Function to calculate the total amount
    const calculateTotalAmount = () => {
        const total = (totalRates + totalTax + adjustment) - discont;
        return Math.round(total)
    };



    const [startDate, setStartDate] = useState(dayjs()); // Default start date
    const [endDate, setEndDate] = useState(); // Default end date
    const [neverExpires, setNeverExpires] = useState(false);

    // Handle the start date change
    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
        // Ensure the end date is not before the selected start date
        if (endDate && newDate.isAfter(endDate)) {
            setEndDate(null);
        }
    };


    // Handle the end date change
    const handleEndDateChange = (newDate) => {
        setEndDate(newDate);
    };

    // Handle the "Never Expires" checkbox
    const handleNeverExpiresChange = (event) => {
        setNeverExpires(event.target.checked);
        if (event.target.checked) {
            // Clear the end date if "Never Expires" is checked
            setEndDate(null);
        }
    };


    return (
        <div>
            <Box sx={style} >
                <div>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '20px' }}> New Recurring Invoice
                        </span>
                    </div><hr />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div>
                            <FormControl sx={{ mt: 1, width: '230px' }}>
                                <InputLabel id="demo-select-small-label">Customer</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={customer}
                                    label="Customer"
                                    onChange={handleCustomer}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                    }}
                                >
                                    <MenuItem value=''>None</MenuItem>
                                    {customerdata.map((elem, ind) => (
                                        elem && elem.first_name ? <MenuItem key={ind} value={elem._id}>{elem.first_name}</MenuItem> : null
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div>
                            <FormControl sx={{ mt: 1, width: '230px' }} >
                                <InputLabel id="demo-select-small-label">Currency</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={currency}
                                    label="Currency"
                                    onChange={handleCurrency}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    <MenuItem value="inr">INR</MenuItem>
                                    <MenuItem value="usd">USD</MenuItem>
                                </Select>
                            </FormControl>
                        </div>


                        <div style={{ width: '230px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}  >
                                    <DatePicker label='Invoice Date' value={invoicedate} onChange={(date) => handleChangedate(date)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <div style={{ width: '230px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <DemoContainer components={['DatePicker']} >
                                    <DatePicker label="Due Date" value={duedate} onChange={(date) => handleChangeDuedate(date)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>

                    </div>
                    <div className='mt-4' style={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap' }}>
                        <div>
                            <div style={{ display: 'flex', gap: '70px' }}>

                                <div>
                                    <FormControl sx={{ width: '230px' }} >
                                        <InputLabel id="demo-select-small-label">Discount Type

                                        </InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={discount}
                                            label="Discount Type"
                                            onChange={handleChangeDiscount}
                                        >
                                            <MenuItem value={0}>No Discount</MenuItem>
                                            <MenuItem value={'Before Tax'}>Before Tax</MenuItem>
                                            <MenuItem value={'After Tax'}>After Tax</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div>
                                <FormControl sx={{ mt: 3, width: '530px' }} >
                                    <InputLabel id="demo-select-small-label">Repeat Every
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={recurringPeriod}
                                        label="Repeat Every?"
                                        onChange={handleChangerecurringPeriod}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                    overflowY: 'auto',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="week">Week</MenuItem>
                                        <MenuItem value="month">Month</MenuItem>
                                        <MenuItem value="3 month">3 Month</MenuItem>
                                        <MenuItem value="year">Year</MenuItem>
                                        <MenuItem value="day">Day</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="mt-3" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                label="Start on"
                                                value={startDate}
                                                onChange={handleStartDateChange}
                                                sx={{ width: '180px' }}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    label="Ends on"
                                                    value={endDate}
                                                    onChange={handleEndDateChange}
                                                    minDate={startDate} // Disable dates before the start date
                                                    disabled={neverExpires} // Disable calendar if "Never Expires" is checked
                                                    sx={{ width: '180px' }}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <div className='mt-4'>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={neverExpires} onChange={handleNeverExpiresChange} />}
                                                    label="Never Expires"
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>

                            </div>




                            <div className='mt-2'>
                                <InputLabel style={{ fontSize: '14px', fontWeight: 'bold', padding: '5px' }}>Admin Note</InputLabel>

                                <TextField
                                    style={{ textAlign: 'left', width: '530px' }} // You can adjust the 'width' value as needed
                                    hintText="Message Field"
                                    floatingLabelText="MultiLine and FloatingLabel"
                                    multiline
                                    rows={2}
                                    value={adminNote}
                                    onChange={handleAdminNoteChange}

                                />
                            </div>
                        </div>

                        <div style={{ marginLeft: "50px" }}>

                            {/* {user && user.currencylist ? (<InputLabel id="demo-single-name-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Currency: <b style={{ color: 'green' }}>{user.currencylist.name}</b></InputLabel>) : <InputLabel id="demo-single-name-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Currency:</InputLabel>}<br />  */}

                            {/* <InputLabel id="demo-single-name-label" style={{ fontSize: '14px', fontWeight: 'bold' }}>Bill To:</InputLabel> */}

                            {user && user.first_name ?
                                (<InputLabel style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>
                                    Bill To:<br />
                                    <span style={{ color: 'blue' }}>{user.first_name} , {user.email}, {user.phone}</span>
                                </InputLabel>
                                ) : <></>}

                            {user && user.billing_addresses
                                ? (<><InputLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    <span style={{ color: 'blue' }}>{user.billing_addresses[0].line_1},{user.billing_addresses[0].line_2},{user.billing_addresses[0].city},{user.billing_addresses[0].state},{user.billing_addresses[0].zipcode},{user.billing_addresses[0].country}</span></InputLabel>   <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Shipping Details:</p>
                                    <select value={shippingCountry} onChange={(e) => handleCountryChange(e, setShippingCountry)} style={{ width: '400px', padding: '7px', borderRadius: '5px' }}>
                                        <option value="">Choose a country...</option>

                                        {Object.keys(countries).map((code) => (
                                            <option key={code} value={code}>
                                                {countries[code].country}
                                            </option>
                                        ))}
                                    </select>
                                    {renderFields(shippingCountry, shippingDetails, handleShippingChange)}</>) : <></>}


                            {/* {user && user.billing_addresses
                ? (<InputLabel style={{ fontSize: '14px', fontWeight: 'bold' }}>Ship To: <br />
                  <span style={{ color: 'blue' }}>{user.billing_addresses[0].line_1},{user.billing_addresses[0].line_2},{user.billing_addresses[0].city},{user.billing_addresses[0].state},{user.billing_addresses[0].zipcode},{user.billing_addresses[0].country}</span></InputLabel>) : <></>} */}

                            <>


                            </>
                        </div>


                    </div>

                    <div style={{ padding: '5px 0px' }} className='mt-3'>
                        <FormControl sx={{ mt: 1, width: '250px' }} >
                            <InputLabel id="demo-select-small-label">Product/Services

                            </InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={product}
                                label="Product/Services"
                                onChange={handleChangeProduct}
                            >
                                <MenuItem value={''}>Custom</MenuItem>
                                {productData.map((elem, index) => {
                                    return (<MenuItem key={index} value={elem._id
                                    }>{elem.name}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='mt-3'>
                        <AppBar position="static" style={{ backgroundColor: 'rgb(50, 50, 59)' }}>
                            <Toolbar
                                sx={{
                                    display: 'flex', justifyContent: 'space-around', borderRadius: '5px'
                                }}
                            >
                                <Typography variant="h11">
                                    Item
                                </Typography>
                                <Typography variant="h11">
                                    Description
                                </Typography>
                                <Typography variant="h11">
                                    Qty
                                </Typography>
                                <Typography variant="h11">
                                    Rate
                                </Typography>
                                <Typography variant="h11">
                                    Tax
                                </Typography>
                                <Typography variant="h11">
                                    Amount
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <div>
                        <div><div style={{ display: 'flex', gap: '10px', marginTop: '30px' }}>
                            <TextField
                                style={{ textAlign: 'left', width: '200px' }} // You can adjust the 'width' value as needed
                                hintText="Message Field"
                                placeholder='Item description'
                                value={input.itemName}
                                onChange={handleChangev15}
                                multiline
                                rows={2}
                            />
                            <TextField
                                style={{ textAlign: 'left', width: '200px' }} // You can adjust the 'width' value as needed
                                hintText="Message Field"
                                placeholder='Long description'
                                value={input.itemDescription}
                                onChange={handleChangev16}
                                floatingLabelText="MultiLine and FloatingLabel"
                                multiline
                                rows={2}


                            />
                            <TextField
                                style={{ width: '150px' }}
                                value={input.quantity}
                                onChange={handleChangev6}
                            // You can add other props and styles here as needed
                            />
                            <TextField
                                style={{ width: '150px' }}
                                placeholder='Rate'
                                value={input.rate}
                                onChange={handleChangev7}
                            // You can add other props and styles here as needed
                            />
                            <FormControl sx={{ width: '130px' }} >
                                <Select
                                    id="demo-select-small"
                                    value={input.tax}
                                    onChange={handleChangev8}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                    }}
                                >
                                    <MenuItem value={0}>
                                        <span>0%</span>
                                    </MenuItem>
                                    <MenuItem value={5}>5%</MenuItem>
                                    <MenuItem value={10}>10%</MenuItem>
                                    <MenuItem value={15}>15%</MenuItem>
                                </Select>
                            </FormControl>
                            <Typography />
                            <AddIcon
                                onClick={addCard}
                                style={{ color: 'green', fontSize: '40px', marginLeft: '45px', cursor: 'pointer' }}
                            />


                        </div>

                            <div>

                                {cards.map((card, index) => (
                                    <InvoiceCard
                                        key={`card-${index}`}
                                        card={card}
                                        onCardDataChange={(field, value) => handleCardDataChange(index, field, value)}
                                        onDelete={() => deleteHandlerCard(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '45%', float: 'right' }}>
                        <hr />
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <div style={{ display: 'flex', gap: '55px', padding: '5px 30px' }}>
                                <Typography style={{ fontSize: '15px' }}>Sub Total:</Typography>
                                {(totalRates).toFixed(2) ? <Typography variant="h11">{(totalRates).toFixed(2)}</Typography> : <Typography variant="h11">{totalRates}.00</Typography>}
                            </div>
                        </div>
                        <hr />
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <div style={{ display: 'flex', gap: '55px', padding: '5px 30px' }}>
                                <Typography style={{ fontSize: '15px' }} >TAX: </Typography>
                                {(totalTax).toFixed(2) ? <Typography variant="h11">{(totalTax).toFixed(2)}</Typography> : <Typography variant="h11">{totalTax}.00</Typography>}
                            </div>
                        </div>
                        <hr />
                        {discount ? <><div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '5px 0px', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
                                    <InputLabel style={{ fontWeight: 'bold', fontSize: '14px' }} >Discount:</InputLabel>
                                    <FormControl sx={{ width: '220px' }} >
                                        <Select
                                            id="demo-select-small"
                                            value={distype}
                                            onChange={handleChangev14}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                            }}
                                        >
                                            <MenuItem value={'flat'}>Flat</MenuItem>
                                            <MenuItem value={'percentage'}>Percentage</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {/* {discont ?<Typography variant="h11">{discont.toFixed(2)}</Typography>:<Typography variant="h11">{discont}.00</Typography>} */}
                                <Typography variant="h11">-{discont}.00</Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <TextField
                                    onChange={handleChangev13}
                                    value={inputvalue} />
                            </div>
                        </div><hr /></> : null}


                        <div style={{ display: 'flex', justifyContent: 'space-around', padding: '5px 0px', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <InputLabel style={{ fontWeight: 'bold', fontSize: '14px' }} >Adjustment:</InputLabel>
                                <TextField
                                    onChange={handleChangev11}
                                    value={adjustment} />
                            </div>
                            {(adjustment).toFixed(2) ? <Typography variant="h11">{(adjustment).toFixed(2)}</Typography> : <Typography variant="h11">{adjustment}.00</Typography>}
                        </div>
                        <hr style={{ border: '0.5px dashed grey' }} />
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <div style={{ display: 'flex', gap: '55px', padding: '5px 25px' }}>
                                <Typography style={{ fontSize: '15px', fontWeight: 'bold' }} >Total: </Typography>
                                {((totalRates + totalTax + adjustment) - discont).toFixed(2) ? <Typography variant="h11">{((totalRates + totalTax + adjustment) - discont).toFixed(2)}</Typography> : <Typography variant="h11">{((totalRates + totalTax + adjustment) - discont)}.00</Typography>}

                            </div>
                        </div>
                    </div>
                    <br />
                    <div style={{ clear: 'both' }}>
                        <hr />
                        <div style={{ marginTop: '50px', display: 'flex', gap: '15px' }}>
                            <Typography variant='h4'>Invoice Notes</Typography>
                            <button style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: 'none', background: 'none' }} onClick={addNotes}>``
                                <AddIcon style={{ fontSize: '30px', color: 'green' }} />
                                <Typography variant='h6' style={{ color: 'green' }}>Add Invoice Notes</Typography>
                            </button>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            {notes.map((notes, index) => (
                                <div>
                                    <InvoiceNote
                                        key={`note-${index}`}
                                        note={notes}
                                        onNoteDataChange={(field, value) => handleNoteDataChange(index, field, value)}
                                        onDelete={() => deleteHandlerNote(index)}
                                    />
                                    <hr />
                                </div>

                            ))}


                        </div>

                    </div>
                    <div style={{ marginTop: '30px', float: 'right' }}>
                        <button style={{ padding: '7px', display: 'flex', alignItems: 'center', gap: '6px', background: 'green', color: 'white', border: 'none', borderRadius: '3px' }} onClick={handleSaveInvoice}>SAVE INVOICE<ArrowForwardIcon style={{ fontSize: '20px' }} /></button>
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default AddRecurringInvoice;