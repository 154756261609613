import SearchBar from "material-ui-search-bar";
import { useState, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { getUserProfile } from "../ApiHandler/apihandler";
// import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';

import {
  FaUserCircle,
  FaUser,
  FaRegCreditCard,
  FaQuestionCircle,
  FaLock,
  FaSignOutAlt,
  FaChevronRight,
} from "react-icons/fa"; // Import icons

export default function Header() {
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    userProfile();
  }, []);

  const userProfile = async () => {
    try {
      const data = await getUserProfile();
      setProfile(data.data.user);
    } catch (error) {
      console.error("Error fetching user profile data:", error);
    }
  };

  const handleSearch = () => {
    // Handle search functionality here
    console.log("Searching for:", searchValue);
  };

  const Logout = () => {
    localStorage.removeItem("auth_token");
    navigate("/login");
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSubscriptionClick = () => {
    navigate("/choose-plan");
  };
  const handleProfileClick = () => {
    navigate("/SettingProfileInfo");
  };

  return (
    <>
      <div
        expand="lg"
        className="container px-2 mt-2 d-flex align-items-center justify-content-end "
      >
        <Nav className="">
          <div className="d-flex justify-content-end align-items-center">
            <CircleNotificationsIcon
              className="nav-icon"
              aria-label="Notifications"
              style={{
                width: "37px",
                height: "37px",
                color: "#ff6f61",
                cursor: "pointer",
              }}
            />
            <IconButton
              aria-controls="account-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              color="inherit"
              className="d-flex align-items-center"
              style={{ backgroundColor: "inherit" }}
            >
              <div
                className="d-flex align-items-center justify-content-center rounded-circle bg-secondary text-white me-2"
                style={{ width: "30px", height: "30px" }}
              >
                {profile ? profile.name.charAt(0).toUpperCase() : ""}
              </div>
              <span style={{ fontSize: "16px" }}>
                {profile ? profile.name : "Loading..."}
              </span>
              <FaChevronRight className="text-secondary" style={{ width: "15px", height: "15px" }} />
            </IconButton>
          </div>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              className: "p-0 shadow-sm",
            }}
          >
            <MenuItem
              onClick={() => {
                handleProfileClick();
                handleMenuClose();
              }}
              className="px-3 py-2 d-flex align-items-center"
            >
              <FaUser className="me-2" />
              <span className="fs-6">My Profile</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSubscriptionClick();
                handleMenuClose();
              }}
              className="px-3 py-2 d-flex align-items-center"
            >
              <FaRegCreditCard className="me-2" />
              <span className="fs-6">Subscription</span>
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              className="px-3 py-2 d-flex align-items-center"
            >
              <FaQuestionCircle className="me-2" />
              <span className="fs-6">Help & Support</span>
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              className="px-3 py-2 d-flex align-items-center"
            >
              <FaLock className="me-2" />
              <span className="fs-6">Change Password</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                Logout();
              }}
              className="px-3 py-2 d-flex align-items-center"
            >
              <FaSignOutAlt className="me-2" />
              <span className="fs-6">Logout</span>
            </MenuItem>
          </Menu>

          {/* <div>
                <IconButton
                  edge="end"
                  aria-controls="account-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit"
                > 
                  <AccountCircleIcon />
                </IconButton>
                <Menu
                  id="account-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>MyProfile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={()=>Logout()}>Logout</MenuItem>
                </Menu>
              </div>       */}
        </Nav>
        {/* </Navbar.Collapse> */}
        {/* </Container> */}
      </div>
      {/* <hr   className="w-100 px-4"/> */}
    </>
  );
}
