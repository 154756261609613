import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSubscriptionByid, getInvoiceById } from '../../../ApiHandler/apihandler';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const PlanDetails = () => {
  
  const { id } = useParams();
  const navigate = useNavigate()
  console.log(id);

  const [data, setData] = useState(null);
  const [invoices, setInvoices] = useState([]); 
  

  useEffect(() => {
    SubscriptionPlanId();
    invoicebyId();
  }, [id]); 

  const SubscriptionPlanId = async () => {
    try {
      const res = await getSubscriptionByid(id);
      console.log(res);
      if (res.status === 200) {
        console.log(res.data, "subscription plan id get");
        setData(res.data);
      } 
    } catch (error) {
      console.log(error);
    }
  };

  const invoicebyId = async () => {
    console.log(invoices, 'invoice bhabesh');
    try {
      const res = await getInvoiceById();
      console.log(res, "response by invoice id");
      if (Array.isArray(res.data.data)) {
        setInvoices(res.data.data);
      } else {
        console.error('Expected data to be an array');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return ( 
    <div className='container'>
      <div className='mt-2'>
        <table className="table table-bordered mt-3">
          <thead>
            <tr className='text-center'>
              <th scope="col">Plan Name</th>
              <th scope="col">Plan Price</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {
              data ? (
                <tr className='text-center'>
                  <th style={{ fontWeight: "normal" }}>{data.plan_name}</th>
                  <td>{data.plan_price}</td>
                  <td>{moment(data.subscription_start_date).format("DD-MM-YYY")}</td>
                  <td>{moment(data.subscription_end_date).format("DD-MM-YYY")}</td>
                  <td>{data.status}</td>
                </tr>
                
              ) : (
                <tr><td colSpan="5">Subscription plan details failed</td></tr>
              )
            }
          </tbody> 
        </table>
      </div>

      <h5>Payments</h5>

      <div className='mt-2'>
        <table className="table table-bordered mt-3">
          <thead>
            <tr className='text-center'>
              <th scope="col">Invoice No</th>
              <th scope="col">Plan Name</th>
              <th scope="col">Invoice Date</th>
              <th scope="col">Due Date</th>
              <th scope="col">Invoice Subtotal</th>
              <th scope="col">Invoice Tax</th>
              <th scope="col">Invoice Total</th>
              {/* <th scope="col">InvoiceSubtotal</th> */}
            </tr>
          </thead>
          <tbody>
            {invoices.length > 0 ? (
              invoices.map((invoice, index) => (
                <tr key={index} className='text-center' style={{ cursor: 'pointer' }} onClick={() =>navigate(`/invoice-details/${invoice._id}`)} > 
                  <th style={{ fontWeight: "normal" }}>{"Invoice001"}</th> 
                  <td>{invoice.plan_name ? invoice.plan_name : invoice.subscription_id.plan_name}</td>
                  <td>{moment(invoice.createdAt).format('DD-MM-YYYY')}</td>
                  <td>{moment(invoice.createdAt).add(3, 'days').format('DD-MM-YYYY')}</td>
                  <td>{invoice.plan_price ? invoice.plan_price : invoice.subscription_id.plan_price}</td>
                  <td>0</td>
                  <td>{(invoice.plan_price ? invoice.plan_price : invoice.subscription_id.plan_price) + 0}</td>
                  {/* <td>dd</td> */}
                </tr>
              ))
            ) : (
              <tr><td colSpan="9">No invoices found</td></tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PlanDetails;
