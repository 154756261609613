import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const EditTypeModal = ({ open, handleClose, item, handleSave }) => {
  const [name, setName] = useState(item?.name || "");

  useEffect(() => {
    setName(item?.name || "");
  }, [item]);

  const handleSaveClick = () => {
    const updatedItem = { name };
    handleSave(updatedItem);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Item Type</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSaveClick}
          variant="contained"
          color="primary"
          sx={{ mt: 1 ,mr:2}}
        >
          Save
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="secondary"
          sx={{ mt: 1, mr: 2 }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTypeModal;
