import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import EmptyDataimg from "./../../../Images/empty.png";
import { useNavigate } from "react-router-dom";

const EmptyDesign = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center justify-content-center ">
      <Card className="border-0 shadow-none">
        <CardContent className="d-flex flex-column align-items-center justify-content-center text-center p-4">
          <div className="d-flex align-items-center justify-content-center w-100 mb-2">
            <img
              src={EmptyDataimg}
              alt="Empty Design"
              className="w-50"
              style={{ height: "auto" }}
            />
          </div>
          <Typography variant="h5" component="div">
            <button
              type="button"
              className="btn text-white"
              style={{ background: "#19c3e6", width: "200px" }}
              onClick={() => navigate("/products-services")}
            >
              Add Product
            </button>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default EmptyDesign;
