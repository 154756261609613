import HomePage from "../Component/Pages/Dashboard/HomePage";
import Login from "../AuthPages/Login";

import InvoicePage from "../Component/Pages/Invoice/InvoicePage";
import ClientPage from "../Component/Pages/Client/ClientPage";
import LayoutDesign from "../Component/Layout/LayoutDesign";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "../Component/Navbar";
import BalancePage from "../Component/Pages/Balance/BalancePage";
import BalancePayment from "../Component/Pages/Balance/BalancePayment";
import CreateClient from "../Component/Pages/Client/CreateClient";
import ProductPage from "../Component/Pages/ProductServices/Product-Services";
import CreateInvoice from "../Component/Pages/Invoice/Create Recurring.js";
import RecuringInvoices from "../Component/Pages/Invoice/Recurring Invoices";
import SettingProfile from "../Component/Setting/SettingProfileInfo";
import SettingUserRoles from "../Component/Setting/SettingUserRoles";
import ProductSale from "../Component/Pages/ProductServices/Product-Sale.js";
import ProductTable from "../Component/Pages/ProductServices/ProductTable.js";
import ClientDetails from "../Component/Pages/Client/ClientDetails";
import { useRoutes } from "react-router-dom";
import SignUp from "../AuthPages/Signup";
import token from "../AuthPages/Token";
import { Navigate } from "react-router-dom";
import AddInvoice from "../Component/Pages/Invoice/CreateInvoice2.js";
import ItemType from "../Component/Setting/ItemType.js";
import AddItemType from "../Component/Setting/CreateItemType.js";
import PremiumCard from "../Component/Pages/Premium/premium.js";
import SubcribePlan from "../Component/Pages/Premium/Subcribeplan.js";
import Payment from "../Component/Pages/Premium/payment.js";
import SucessPage from "../Component/Pages/Premium/Sucess.js";
import Downloadinvoice from "../Component/Pages/Invoice/Downloadinvoice .js";
import DownloadRecurringInvoice from "../Component/Pages/Invoice/Downloadrecurringinvoice.js";
import Subscription from "../Component/Pages/Subscription/subscription.js"
import PlanDetails from "../Component/Pages/Premium/PlanDetails.js";
import InvoiceDownload from "../Component/Pages/Premium/DowmloadInvoices.js";
import ChoosePlan from "../Component/Pages/Premiums/ChoosePlan.js";
import Customize from "../Component/Pages/Premiums/Customize.js";
import Checkout from "../Component/Pages/Premiums/Checkout.js";
import AddRecurringInvoice from "../Component/Pages/Invoice/Create Recurring.js";

export default function InvorailRoute() {
    let routes = useRoutes([
        {
            path: '/',
            element: token() ? <LayoutDesign /> : <Navigate to={'/login'} />,
            // element: <Login/>,
            // element: <LayoutDesign />,

            children: [
                { path: '/home', element: <HomePage /> },
                { path: 'invoice', element: <InvoicePage /> },
                { path: 'create-invoice', element: <AddInvoice /> },
                { path: 'recurring-invoices', element: <RecuringInvoices /> },
                { path: 'create-recurring-invoice', element: <AddRecurringInvoice /> },
                { path: 'invoice/:id', element: <Downloadinvoice /> },
                { path: 'recurring-invoice/:id', element: <DownloadRecurringInvoice /> },
                { path: "client", element: <ClientPage /> },
                { path: "add-client", element: <CreateClient /> },
                { path: 'products-services', element: <ProductPage /> },
                { path: 'products-sale', element: <ProductSale /> },
                { path: 'products-table', element: <ProductTable /> },
                { path: 'balance-info', element: <BalancePage /> },
                { path: "balance-payment", element: <BalancePayment /> },
                { path: "SettingProfileInfo", element: <SettingProfile /> },
                // {path:"deatailspage/:id",element:<DeatailsPage/>},
                // {path:"support-newticket",element:<Newticket/>},
                { path: "settinguser", element: <SettingUserRoles /> },
                { path: "itemtype", element: <ItemType /> },
                { path: "additemtype", element: <AddItemType /> },
                { path: "clientdetailes/:id", element: <ClientDetails /> },
                { path: "premium", element: <PremiumCard /> },
                { path: "subcribeplan/:id", element: <SubcribePlan /> }, 
                { path: "stripe-payment/:id", element: <Payment /> },
                { path: "success", element: <SucessPage /> },
                { path: "subscription", element: <Subscription /> },
                { path: "plan-details/:id", element: <PlanDetails /> },
                { path: "invoice-details/:id", element: <InvoiceDownload /> },
                // stripenew desin route
                { path: "choose-plan", element: <ChoosePlan /> },
                { path: "customize/:id", element: <Customize /> },
                { path: "customize", element: <Customize /> },
                { path: "checkout/:id", element: <Checkout /> },


            ]
        }, 
        { path: 'login', element: <Login /> },
        { path: 'invoices/:id', element: <Downloadinvoice /> },
        { path: 'recurring-invoices/:id', element: <DownloadRecurringInvoice /> },
        { path: 'signup', element: <SignUp /> },
    ])
    return routes;
}

