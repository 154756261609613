import React, { useState } from 'react'
import swal from "sweetalert"
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';



const CreateClient = () => {

    const navigate = useNavigate();


    const data = {
        // name: "",
        email: "",
        contact: "",
        company: "",
        businesstype: "individual",
        first_name: "",
        last_name: "",
        line_1: "",
        line_2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
    }

    const [clientdata, setClientdata] = useState(data);
    const token = localStorage.getItem('auth_token');

    const handleClientdata = async (event) => {

        // Validate required fields
        // if ( !clientdata.email || !clientdata.contact || !clientdata.company || !clientdata.first_name || !clientdata.last_name || !clientdata.line_1 ||  !clientdata.line_2 || !clientdata.city || !clientdata.state || !clientdata.country || !clientdata.zipcode) {
        //     Swal.fire({
        //         title: "Error",
        //         text: "Please fill all required fields",
        //         icon: "error",
        //         confirmButtonText: "OK",
        //         confirmButtonColor: "#19c3e6",
        //         buttonsStyling: true,
        //     });
        //     return;
        // }
        console.log(token, "token---")
        // event.preventDefault();
        try {
            console.log(clientdata, "clientdata-----")
            const result = await axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/client/addclient`, clientdata, {
                headers: {
                    'x-auth-token': token
                }
            });
            if (result.status === 200) {
                Swal.fire({
                    title: "Success",
                    text: "Client added successfully!",
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#19c3e6",
                    buttonsStyling: true,
                });

                navigate(`/client`)
            }


        } catch (error) {
            swal("", error, "danger")
        }
    }


    const handleDiscard = () => {
        setClientdata(data); // Reset clientdata state to initial data
    };




    return (
        <>
            <div className="container px-2">
                <div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <div><h5 style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>Clients</h5></div>

                        <div >
                            <span>
                                <button type="button" className="btn border border-primary" onClick={() => navigate(`/client`)}
                                >Preview</button>
                            </span>


                            <span style={{ marginLeft: "20px" }}>
                                <button type="button" className="btn" style={{ background: "#19c3e6" }}> Add Clients</button>
                            </span>
                        </div>
                    </div>


                    <div className='row mt-4'>

                        <div className='col-md-6'>

                            {/* <div className='form-group'>
                                <label for="clientName1" className="form-label ">Client Name</label>
                                <input type="text" value={clientdata.name} className="form-control border border-primary" id="clientName1 " style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, name: e.target.value })} />
                            </div> */}
                            <div className="">
                                <label htmlFor="clientName1" className="form-label">First Name</label>
                                <input type="text" value={clientdata.first_name} className="form-control border border-primary" id="clientName1" style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, first_name: e.target.value })} />
                            </div>
                            {/* <div className=" form-group">
            <label htmlFor="clientName2" className="form-label">Last Name</label>
            <input type="text" value={clientdata.last_name} className="form-control border border-primary" id="clientName2" style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, last_name: e.target.value })} />
        </div> */}
                            <div className='form-group'>
                                <label for="type1" className="form-label">Company</label>
                                <input type="text" className="form-control  border border-primary" id="type1" style={{ background: "#D0F0FF" }} value={clientdata.company} onChange={(e) => setClientdata({ ...clientdata, company: e.target.value })} />
                            </div>


                            <div className='form-group mt-2'>
                                <label for="clientName2" className="form-label">Email</label>
                                <input type="text" className="form-control  border border-primary" id="clientName2" style={{ background: "#D0F0FF" }} value={clientdata.email} onChange={(e) => setClientdata({ ...clientdata, email: e.target.value })} />
                            </div>

                            <div className='form-group  mt-2'>
                                <label for="clientName3" className="form-label">Contact No</label>
                                <input type="text" className="form-control  border border-primary" id="clientName3" style={{ background: "#D0F0FF" }} value={clientdata.contact} onChange={(e) => setClientdata({ ...clientdata, contact: e.target.value })} />
                            </div>

                        </div>

                        <div className='col-md-6'>

                            {/* <div className='form-group'>
                                <label for="type1" className="form-label">Company</label>
                                <input type="text" className="form-control  border border-primary" id="type1" style={{ background: "#D0F0FF" }} value={clientdata.company} onChange={(e) => setClientdata({ ...clientdata, company: e.target.value })} />
                            </div> */}
                            <div className=" form-group">
                                <label htmlFor="clientName2" className="form-label">Last Name</label>
                                <input type="text" value={clientdata.last_name} className="form-control border border-primary" id="clientName2" style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, last_name: e.target.value })} />
                            </div>

                            <div className='form-group mt-2'>
                                <label for="type2" className="form-label">Bussiness Type</label>
                                <input type="text" className="form-control  border border-primary" id="type2" style={{ background: "#D0F0FF" }} value={clientdata.businesstype} onChange={(e) => setClientdata({ ...clientdata, businesstype: e.target.value })} />
                            </div>

                            {/* <div className='form-group mt-2'>
                                <label for="type3" className="form-label">Contact No</label>
                                <input type="text" className="form-control  border border-primary" id="type3" style={{ background: "#D0F0FF" }} value={clientdata.contact} onChange={(e) => setClientdata({ ...clientdata, contact: e.target.value })} />
                            </div> */}

                        </div>

                        <div className='col-md-12 mt-2' >
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Address</label>
                                <div className="row">

                                    <div className="col-md-3 form-group">
                                        <label htmlFor="clientLine1" className="form-label">Address Line1</label>
                                        <input type="text" value={clientdata.line_1} className="form-control border border-primary" id="clientLine1" style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, line_1: e.target.value })} />
                                    </div>
                                    <div className="col-md-3 form-group">
                                        <label htmlFor="clientLine2" className="form-label">Address Line2</label>
                                        <input type="text" value={clientdata.line_2} className="form-control border border-primary" id="clientLine2" style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, line_2: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 form-group">
                                        <label htmlFor="clientCity" className="form-label">City</label>
                                        <input type="text" value={clientdata.city} className="form-control border border-primary" id="clientCity" style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, city: e.target.value })} />
                                    </div>
                                    <div className="col-md-3 form-group">
                                        <label htmlFor="clientState" className="form-label">State</label>
                                        <input type="text" value={clientdata.state} className="form-control border border-primary" id="clientState" style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, state: e.target.value })} />
                                    </div>
                                    <div className="col-md-3 form-group">
                                        <label htmlFor="clientCountry" className="form-label">Country</label>
                                        <input type="text" value={clientdata.country} className="form-control border border-primary" id="clientCountry" style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, country: e.target.value })} />
                                    </div>
                                    <div className="col-md-3 form-group">
                                        <label htmlFor="clientZipcode" className="form-label">Zipcode</label>
                                        <input type="text" value={clientdata.zipcode} className="form-control border border-primary" id="clientZipcode" style={{ background: "#D0F0FF" }} onChange={(e) => setClientdata({ ...clientdata, zipcode: e.target.value })} />
                                    </div>
                                </div>
                            </div>


                            <div className='d-flex justify-content-end mt-4 mb-4'>
                                <span>
                                    <button type="button" className="btn border border-primary" onClick={handleDiscard}
                                    >Discard</button>
                                </span>


                                <span style={{ marginLeft: "20px" }}>
                                    <button type="button" className="btn   text-white" style={{ background: "#19c3e6" }} onClick={() => handleClientdata()}>Save</button>
                                </span>
                            </div>
                        </div>



                    </div>


                </div>







            </div>



        </>
    )
}

export default CreateClient