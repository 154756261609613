import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import moment from 'moment';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { getItemTypes, updateItemType,deleteItemType } from '../../ApiHandler/apihandler';
import EditTypeModal from './EditTypeModal';

export default function ItemType() {
    const navigate = useNavigate();
    const [allitem, setallitem] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        getitemType();
    }, []);

    const getitemType = async () => {
        try {
            const res = await getItemTypes();
            console.log(res, "res--->>");
            setallitem(res.data.ItemType);
        } catch (error) {
            console.error(error);
        }
    };

    const handleEditClick = (item) => {
        setSelectedItem(item);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedItem(null);
    };

    const handleSave = async (updatedItem) => {
        try {
            const response = await updateItemType(selectedItem._id, updatedItem);
            if (response.status === 200) {
                swal("Success", "Item type updated successfully", "success");
                getitemType();
                handleCloseModal();
            }
        } catch (error) {
            console.error(error);
            swal("Error", "Failed to update item type", "error");
        }
    };

    // 
    const handleDelete = async (id)=> {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#19c3e6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            });

            if (result.isConfirmed) {
                const response = await deleteItemType(id)
;
                if (response.status === 200) {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your items has been deleted.",
                        icon: "success",
                        confirmButtonColor: "#19c3e6"
                    });
                    getitemType();
                }
            }
        } catch (error) {
            console.error("Error deleting plan:", error);
        }
    };

    return (
        <>
            <div className="container px-4">
                <div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <div><h5 style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>ITEM TYPES</h5></div>
                        <div>
                            <button type="button" className="btn text-white" style={{ background: "#19c3e6" }}
                                onClick={() => navigate('/additemtype')}> Add Item Type</button>
                        </div>
                    </div>
                </div>
                <div>
                    <Card className='mt-4' style={{ border: "2px solid #01ACFF" }}>
                        <div className='maintable'>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='left' style={{ fontWeight: "600", fontStyle: "normal" }}>ID</TableCell>
                                        <TableCell align="left" style={{ fontWeight: "600", fontStyle: "normal" }}>Name</TableCell>
                                        <TableCell align="left" style={{ fontWeight: "600", fontStyle: "normal" }}>During Date</TableCell>
                                        <TableCell align="left" style={{ fontWeight: "600", fontStyle: "normal" }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allitem.map((row, index) => (
                                        <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">{index + 1}</TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{moment(row.createdAt).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="left">
                                                <span className='text-danger' onClick={()=>handleDelete(row._id)}><DeleteIcon style={{ height: "18px", width: "18px" ,cursor:"pointer"}} /></span>
                                                <span onClick={() => handleEditClick(row)}><EditNoteIcon style={{ height: "22px", width: "22px" ,cursor:"pointer"}}  /></span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </Card>
                </div>
            </div>
            <EditTypeModal 
                open={openModal} 
                handleClose={handleCloseModal} 
                item={selectedItem} 
                handleSave={handleSave} 
            />
        </>
    );
}
