import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PreviewIcon from '@mui/icons-material/Preview';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useNavigate } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import { getProducts, updateProduct, deleteProduct } from '../../../ApiHandler/apihandler';
import moment from 'moment';
import EditProductModal from './EditProductModal';
import swal from 'sweetalert';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import EmptyProduct from '../ProductServices/EmptyProduct';

export default function ProductTable() {
  const navigate = useNavigate();
  const [allproducts, setAllproducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getproduct();
  }, []);

  const getproduct = async () => {
    try {
      const data = await getProducts();
      console.log(data.data.data, 'data---------><----');
      if (data.status === 200) {
        setAllproducts(data.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const data = {
      name: selectedProduct.name,
      type: selectedProduct.type,
      unitPrice: selectedProduct.unitPrice,
    };
    const response = await updateProduct(selectedProduct._id, data);
    if (response.status === 200) {
      swal('Success', 'Products updated successfully', 'success');
      getproduct();
      handleClose();
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#19c3e6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (result.isConfirmed) {
        const deleteResponse = await deleteProduct(id);
        if (deleteResponse.status === 200) {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your product data has been deleted.',
            icon: 'success',
            confirmButtonColor: '#19c3e6',
          });
          getproduct();
          if (allproducts.length === 1) {
            setAllproducts([]);
          }
        }
      }
    } catch (error) {
      console.error('Error deleting plan:', error);
    }
  };

  return (
    <>
      <div className="container px-4">
        {!loading && allproducts.length === 0 ? (
          <EmptyProduct />
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div>
                <h5 style={{ fontWeight: 'bold', fontFamily: 'sans-serif' }}>Products/Services</h5>
              </div>
              <div>
                <button
                  type="button"
                  className="btn text-white"
                  style={{ background: '#19c3e6' }}
                  onClick={() => navigate('/products-services')}
                >
                  Add Product
                </button>
              </div>
            </div>

            {loading ? (
              <div className="d-flex align-items-center justify-content-center" style={{ marginTop: '150px' }}>
                <CircularProgress />
              </div>
            ) : (
              <Card className="mt-2" style={{ border: '1px solid #01ACFF' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Product Name</TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Unit Price</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allproducts.map((row, index) => (
                      <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.unitPrice}</TableCell>
                        <TableCell align="left">
                          <span className="text-danger" onClick={() => handleDelete(row._id)}>
                            <DeleteIcon style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                          </span>
                          <span onClick={() => handleOpen(row)}>
                            <EditNoteIcon style={{ width: '23px', height: '23px', cursor: 'pointer' }} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            )}
          </>
        )}
      </div>

      <EditProductModal
        open={open}
        handleClose={handleClose}
        product={selectedProduct}
        handleSave={handleSave}
        handleInputChange={handleInputChange}
      />
    </>
  );
}
