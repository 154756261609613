import './premium.css'
import { useNavigate } from 'react-router-dom';


export default function SucessPage() {

    const navigate = useNavigate();

    const handleBack = () =>{
      navigate('/choose-plan')
      // window.location.reload();
    } 
         
    return(
        <>
        <div className="px-4 pb-4">
      <div className="payment-done-content">
        <div className="">
          <svg height="100" width="100">
            <circle cx="50" cy="50" r="40" fill="green" />
            <line x1="30" y1="50" x2="45" y2="65" stroke="white" strokeWidth="5" />
            <line x1="45" y1="65" x2="70" y2="35" stroke="white" strokeWidth="5" />
          </svg>
        </div>
        <h1>Payment Done!</h1>
        <p>Thank you for completing your secure online payment.</p>
        <p>Have a great day!</p>
        <button className="go-back-button" onClick={() => handleBack()}style={{ background: "#19c3e6" }}>GO BACK</button>
      </div>
    </div>       
        </>
    )
}