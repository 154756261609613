import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { createPaymentIntent,verifyPayment } from '../../../ApiHandler/apihandler';
import CircularProgress from '@mui/material/CircularProgress';

const stripePromise = loadStripe('pk_test_51PVdhuLwXNdguAahbR3CdwoeSpZoJDyIJTGPrJpjsRV6TEUTwEpA04OVeo3QUav2v8kx63PFuVYrhgH3wnFpgn9p00Nd1OloQy');
 
const PaymentForm = () => {

    const userID = localStorage.getItem('user_id');
    const { state } = useLocation();
    const [amount, setAmount] = useState(state.amount);
    const [planName, setPlanName] = useState(state.planName); 
    const [planid, setPlanid] = useState(state.planid);
    const [startdate, setStartdate] = useState(state.startdate);
    const [enddate, setEnddate] = useState(state.enddate);
    const [paymentIntentId, setPaymentIntentId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [isCardComplete, setIsCardComplete] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    

    useEffect(() => { 
        if (showLoader) { 
            const timer = setTimeout(() => {
                navigate('/success');
                window.location.reload(); 
            }, );
            return () => clearTimeout(timer);
        }
    }, [showLoader, navigate]); 

    const handleSubmit = async (event) => {
        event.preventDefault();
      
        if (!stripe || !elements) {
          return;
        }
      
        if (!amount || amount <= 0) {
          setMessage('Please enter a valid amount.');
          return;
        }
      
        if (!isCardComplete) {
          setMessage('Please enter your card information.');
          return;
        }
      
        setIsLoading(true);
      
        try {
          const { clientSecret } = await createPaymentIntent(amount, planName, planid, startdate, enddate, userID);
      
          const cardElement = elements.getElement(CardElement);
          const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: 'Test User',
              },
            },
          });
      
          if (error) {
            console.error(error);
            setMessage(`Payment failed: ${error.message}`);
            navigate('/failure');
          } else {
            console.log('Payment successful:', paymentIntent);
            setPaymentIntentId(paymentIntent.id);
            setMessage('Payment successful!');
            setShowLoader(true); // Show loader for 10 seconds
      
            // Call the verification endpoint
            await verifyPayment(paymentIntent.id, amount, planName, planid, startdate, enddate, userID);
            navigate('/success');
          }
        } catch (error) {
          console.error('Error processing payment:', error);
          setMessage('Error processing payment. Please try again.');
          navigate('/failure');
        }
      
        setIsLoading(false);
      };
      
      

    const handleCardChange = (event) => {
        setIsCardComplete(event.complete);
    };

    const handleCancelPayment = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_WEB_URL}api/v1/payment/cancel-payment`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentIntentId }),
          });
    
          const data = await response.json();
    
          if (response.ok) {
            setMessage('Payment cancelled successfully.');
          } else {
            setMessage(`Failed to cancel payment: ${data.message}`);
          }
        } catch (error) {
          setMessage(`Error: ${error.message}`);
        }
      };
    

    return (
        <form onSubmit={handleSubmit} className="payment-form" style={{ marginTop: "26vh" }}>
            <h2>Payment Form</h2>
            <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount"
                className="amount-input"
            />
            <CardElement
                className="card-element"
                onChange={handleCardChange}
                options={{
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}
            />
            <button type="submit" disabled={!stripe || isLoading || !isCardComplete} className="submit-button">
                {isLoading || showLoader ? <CircularProgress size={24} /> : 'Pay'} 
            </button>
            {/* {message && <p className="message">{message}</p>} */}
            <button className='mt-2' onClick={handleCancelPayment}> Cancel </button>
        </form>
    );
};

const Payment = () => (
    <Elements stripe={stripePromise}> 
        <PaymentForm />
    </Elements>
);

export default Payment;
