import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { sendOTP, verifyOTP } from '../../../ApiHandler/apihandler';


const EmailOtpModal = ({ isOpen, onClose,setShowModal,actionType,setShowCancelReasonModal,Id,clientEmail }) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSendOtp = async () => {
    setLoading(true);
    setError(null); // Reset any previous errors

    const data = {
        clientEmail: clientEmail, // Email stored in the client record
        email: email,             // Email typed by the user
        Id: Id                    // Any additional ID if required
    };

    try {
        const response = await sendOTP(data); // API call to send OTP
        console.log(response, "email response check");

        // Check if the response indicates success
        if (response.data.success) {
            setTimeout(() => {
                setOtpSent(true); // OTP sent successfully
                setLoading(false);
            }, 2000);
        } else {
            // Handle unsuccessful response
            setError(response.data.message || 'Invalid Email. Please try again.');
            setLoading(false);
        }
    } catch (error) {
        // Handle any errors that occur during the API call
        console.error("Error sending OTP:", error);
        setLoading(false);
        setError('You are not connected to us . Please try again.'); // Generic error message
    }
};


  const handleVerifyOtp = async () => {
    setLoading(true);
    setError(null);
    // Simulate OTP verification API call
    const data = {
        email:email,
        otp:otp
    }
    const response = await verifyOTP(data);
    if(response.data.success){
        setLoading(false);
        setOtp('');
        onClose();
        if (actionType === 'accept') {
            setShowModal(true); // Opens the payment form modal for "Accept"
          } else if (actionType === 'cancel') {
            setShowCancelReasonModal(true); // Opens the cancellation reason modal for "Cancel"
          }
        } else {
          setError('Invalid OTP. Please try again.');
          setLoading(false);
        }
    
    console.log(response,"otpverification ----<..>")
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Email Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {otpVerified ? (
          <div>Access Granted!</div>
        ) : (
          <div>
            {!otpSent ? (
              <Form>
                <Form.Group controlId="emailInput">
                  <Form.Label>Email ID:</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                  />
                </Form.Group>
                <Button variant="primary" onClick={handleSendOtp} disabled={loading}>
                  {loading ? <Spinner as="span" animation="border" size="sm" /> : "Send OTP"}
                </Button>
              </Form>
            ) : (
              <Form>
                <Form.Group controlId="otpInput">
                  <Form.Label>Enter OTP:</Form.Label>
                  <Form.Control
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                    placeholder="Enter the OTP"
                  />
                </Form.Group>
                <Button variant="primary" onClick={handleVerifyOtp} disabled={loading}>
                  {loading ? <Spinner as="span" animation="border" size="sm" /> : "Verify OTP"}
                </Button>
              </Form>
            )}
          </div>
        )}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailOtpModal;
