import React, { useState,useEffect } from 'react';
import "./Style/Invorail.css";
import sidebarimg from "../Images/logo.png";
import { IoMdHome } from "react-icons/io";
import { TbFileInvoice } from "react-icons/tb";
import { IoIosContacts } from "react-icons/io";
import { MdConnectWithoutContact } from "react-icons/md";
import { MdAccountBalanceWallet } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getAllSubscriptions } from '../ApiHandler/apihandler';



const Sidebar = () => {

  const [activeItem, setActiveItem] = useState('');
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
  const [isSettingOpen, setIsSettingOPen] = useState(false)
  const [isProductOpen, setIsProductOpen] = useState(false)
  const [isBalanceOpen, setIsBalanceOpen] = useState(false)
  const [subscription,setsubscription]= useState(false)

  const navigate = useNavigate(); 
  
  useEffect(() => {
    GetAllSubscriptionsplan()
  }, []);

  const handleClick = (item) => {  
    setActiveItem(item);
    if (item === 'INVOICE') {
      setIsInvoiceOpen(!isInvoiceOpen);
    } else {
      setIsInvoiceOpen(false);
    }
  };

  const GetAllSubscriptionsplan = async () => {
    try {
      const res = await getAllSubscriptions();
      console.log(res, "all subscriptions -------->");
      if(res.data.length>0){
        setsubscription(true);

      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };


  const handleClickSetting = (item) => {
    setActiveItem(item);
    if (item === 'SETTING') {
      setIsSettingOPen(!isSettingOpen);
    } else {
      setIsSettingOPen(false);
    }
  };

  const handleClickProduct = (item) => {
    setActiveItem(item);
    if (item === 'PRODUCT') {
      setIsProductOpen(!isProductOpen);
    } else {
      setIsProductOpen(false);
    }
  };


  const handleClickBalance = (item) => {
    setActiveItem(item);
    if (item === 'BALANCE') {
      setIsBalanceOpen(!isBalanceOpen);
    } else {
      setIsBalanceOpen(false);
    }
  };






  return (
    <div className="Sidebar" >
      <div className='navigation'>
        <ul>
          <li><img src={sidebarimg} alt="logo" /></li> 


          <Link to={"/home"} className='linkuderline'>
            <li className={activeItem === 'HOME' ? 'active' : ''} onClick={() => handleClick('HOME')}>
              <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <span style={{ fontSize: '24px' }}><IoMdHome className='imagesidebar' /></span>
                <span className='mt-2'>DASHBOARD</span>
              </div>
            </li>
          </Link>


          <li className={activeItem === 'INVOICE' ? 'active' : ''} onClick={() => handleClick('INVOICE')}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <span style={{ fontSize: '24px' }}><TbFileInvoice className='imagesidebar' /></span>
              <span className='mt-2'>INVOICE</span>
              <span className='mt-2'>{isInvoiceOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}</span>
            </div>
          </li>

          {isInvoiceOpen && (
            <div className={`submenu p-3 ${isInvoiceOpen ? 'open' : ''}`} style={{ backgroundColor: '#01ACFF', marginTop: "-10px", borderRadius: "5px" }}>

              <div className={activeItem === 'Invoice' ? 'active' : ''} onClick={() => handleClick('Invoice')}>
                <Link to={"/invoice"} className='p-4 text-white  '>INVOICE</Link>
              </div>

              <div style={{ marginTop: "10px" }} className={activeItem === 'Recurring Invoices' ? 'active' : ''} onClick={() => handleClick('Recurring Invoices')}>
                <Link to={"/recurring-invoices"} className='p-4 text-white'>RECURRING INVOICE</Link>
              </div>

              {/* <div style={{ marginTop: "10px", }} className={activeItem === 'Invoices / Templates' ? 'active' : ''} onClick={() => handleClick('Invoices / Templates')}>
                <Link to={"/"} className='p-4 text-white '>INVOICE TEMPLATES</Link>
              </div> */}

            </div>


          )}


          <Link to={"/client"}>
            <li className={activeItem === 'CLIENTS' ? 'active' : ''} onClick={() => handleClick('CLIENTS')}>
              <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <span style={{ fontSize: '24px' }}><IoIosContacts className='imagesidebar' /></span>
                <span className='mt-2'>CLIENTS</span>
              </div>
            </li>
          </Link>




          <li className={activeItem === 'PRODUCT' ? 'active' : ''} onClick={() => handleClickProduct('PRODUCT')}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <span style={{ fontSize: '24px' }}><MdConnectWithoutContact className='imagesidebar' /></span>
              <span className='mt-2'>PRODUCTS / SERVICES</span>
              <span className='mt-2'>{isProductOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}</span>
            </div>
          </li>

          {isProductOpen && (
            <div className={`submenu p-3 ${isProductOpen ? 'open' : ''}`} style={{ backgroundColor: '#01ACFF', marginTop: "-10px", borderRadius: "5px" }}>

              <div className={activeItem === 'Products/Services' ? 'active' : ''} onClick={() => handleClickProduct('Products/Services')}>
                <Link to={"/products-table"} className='p-4 text-white '>PRODUCT SERVICES</Link>
              </div>

              <div style={{ marginTop: "10px" }} className={activeItem === 'Product Sale' ? 'active' : ''} onClick={() => handleClickProduct('Product Sale')}>
                <Link to={"/products-sale"} className='p-4 text-white'>PRODUCT SALE</Link>
              </div>


            </div>


          )}



          <li className={activeItem === 'BALANCE' ? 'active' : ''} onClick={() => handleClickBalance('BALANCE')}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <span style={{ fontSize: '24px' }}><MdAccountBalanceWallet className='imagesidebar' /></span>
              <span className='mt-2'>BALANCE</span>
              <span className='mt-2'>{isBalanceOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}</span>
            </div>
          </li>

          {isBalanceOpen && (
            <div className={`submenu p-3 ${isBalanceOpen ? 'open' : ''}`} style={{ backgroundColor: '#01ACFF', marginTop: "-10px", borderRadius: "5px" }}>

              <div className={activeItem === 'Balance Info' ? 'active' : ''} onClick={() => handleClickBalance('Balance Info')}>
                <Link to={"/balance-info"} className='p-4 text-white '>BALANCE INFO</Link>
              </div>

              <div style={{ marginTop: "10px" }} className={activeItem === 'Balance / Payment Method' ? 'active' : ''} onClick={() => handleClickBalance('Balance / Payment Method')}>
                <Link to={"/balance-payment"} className='p-4 text-white'>BALANCE PAYMENT </Link>
              </div>


            </div>


          )}


          <li className={activeItem === 'SETTING' ? 'active' : ''} onClick={() => handleClickSetting('SETTING')}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <span style={{ fontSize: '24px' }}><IoSettingsSharp className='imagesidebar' /></span>
              <span className='mt-2'>SETTING</span>
              <span className='mt-2'>{isSettingOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}</span>
            </div>
          </li>

          {isSettingOpen && (
            <div className={`submenu p-3 ${isSettingOpen ? 'open' : ''}`} style={{ backgroundColor: '#01ACFF', marginTop: "-10px", borderRadius: "5px" }}>

              <div className={activeItem === 'Setting Profile Info' ? 'active' : ''} onClick={() => handleClickSetting('Setting Profile Info')}>
                <Link to={"/SettingProfileInfo"} className='p-4 text-white '>SETTING PROFILE INFO</Link>
              </div>

              <div style={{ marginTop: "10px" }} className={activeItem === 'Setting / USER ROLES' ? 'active' : ''} onClick={() => handleClickSetting('Setting / USER ROLES')}>
                <Link to={"/settinguser"} className='p-4 text-white'>SETTING USER ROLES</Link>
              </div>
              <div className={activeItem === 'Setting Profile Info' ? 'active' : ''} onClick={() => handleClickSetting('Setting Profile Info')}>
                <Link to={"/itemtype"} className='p-4 text-white '>ITEM TYPE</Link>
              </div>


            </div>


          )}
           <div>
    {subscription ? "" : (
      <div style={{ paddingTop: "70px" }}>
        <h5 style={{ color: "white" }}>Get Premium Features</h5>
        <h6 style={{ color: "white", marginLeft: "25px", marginTop: "5px" }}>Get Premium Features</h6>
        <button
          style={{ width: "150px", height: "31px", border: "none", marginTop: "10px", marginLeft: "20px", borderRadius: "9px", background: "#FFF", color: "#46CAE3", fontSize: "16px", fontWeight: "bold" }}
          className='border-0'
          onClick={() => navigate(`/choose-plan`)}
        >
          Get Premium Now
        </button>
      </div>
    )}
  </div>

          

        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
