import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function EditProductModal({ open, handleClose, product, handleSave, handleInputChange }) {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6" component="h2">
          Edit Product
        </Typography>
        {product && (
          <Box component="form" sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Product Name"
              name="name"
              value={product.name}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Type"
              name="type"
              value={product.type}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Unit Price"
              name="unitPrice"
              value={product.unitPrice}
              onChange={handleInputChange}
              margin="normal"
            />
            <Button 
            onClick={()=>{
              handleSave();
            }} variant="contained" color="primary" sx={{ mt: 2 }}>
              Save
            </Button>
            <Button onClick={handleClose} variant="outlined" color="secondary" sx={{ mt: 2, ml: 2 }}>
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
