import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useNavigate } from 'react-router-dom';
import { getAllInvoice, deleteInvoice } from '../../../ApiHandler/apihandler';
import moment from 'moment';
// import EmptyLogo from './../../Images/empty.png';
import EmptyLogo from './../../../Images/empty.png'
import { Card, CardContent,  } from '@mui/material';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';

export default function InvoicePage() {
  const navigate = useNavigate();
  const [invoiceData, setInvoicedata] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Getinvoice();
  }, []);

  const Getinvoice = async () => {
    const response = await getAllInvoice();

    if (response.status === 200) {
      console.log(response.data, "get all invoice data");
      setInvoicedata(response.data.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleDeleteInvoice = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#19c3e6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });
      if (result.isConfirmed) {
        const deleteResponse = await deleteInvoice(id);
        if (deleteResponse.status === 200) {
          Swal.fire({
            title: "Deleted!",
            text: "Your invoice data has been deleted.",
            icon: "success",
            confirmButtonColor: "#19c3e6"
          });
          Getinvoice();
        }
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
    }
  };

  return (
    <div className="container px-4 mt-4">
    
       

      <div>
        {loading ? (
          <div className="d-flex align-items-center justify-content-center" style={{ marginTop: '150px' }}>
            <CircularProgress />
          </div>
        ) : ( 
          invoiceData.length === 0 ? (
            <div className="d-flex align-items-center justify-content-center ">
      <Card className="border-0 shadow-none">
        <CardContent className="d-flex flex-column align-items-center justify-content-center text-center p-4">
          <div className="d-flex align-items-center justify-content-center w-100 mb-2">
            <img
              src={EmptyLogo}
              alt="Empty Design"
              className="w-50"
              style={{ height: 'auto' }}
            />
          </div>  
            <div>
            <button
                type="button"
                className="btn text-white"
                style={{ background: "#19c3e6" }}
                onClick={() => navigate("/create-invoice")}
              >
                Create Invoice
              </button>
            </div>
        </CardContent>
      </Card>
    </div>
          ) : (

            <>
            <div>
            <div className="d-flex justify-content-between align-items-center ">
              <h5 style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>Invoices</h5>
              <button
                type="button"
                className="btn text-white"
                style={{ background: "#19c3e6" }}
                onClick={() => navigate("/create-invoice")}
              >
                Create Invoice
              </button>
            </div>
          </div>
            <Card className='mt-2'>
              <div className='maintable'>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Customer</TableCell>
                      <TableCell align="left">Amount</TableCell>
                      <TableCell align="left">Create Date</TableCell>
                      <TableCell align="left">Due Date</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData.map((row) => (
                      row.recurringInvoice === 'no'?
                      <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left">{row?.customer?.first_name}</TableCell>
                        <TableCell align="left">{row.totalAmount}</TableCell>
                        <TableCell align="left">{moment(row.invoice_date).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="left">{moment(row.due_date).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="left" style={{ cursor: "pointer" }}>
                          <span onClick={() => navigate(`/invoice/${row._id}`)}><RemoveRedEyeIcon /></span>
                          <span onClick={() => handleDeleteInvoice(row._id)}><DeleteIcon className='text-danger pointer' /></span>
                        </TableCell>
                      </TableRow>:""
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Card>
            </>
          )
        )}
      </div>
    </div>
  );
}
