import React, { useState, useEffect } from 'react';
import "./premium.css";
import { useParams } from 'react-router-dom';
import { Invoiceid } from '../../../ApiHandler/apihandler';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useRef } from 'react';
const InvoiceDownload = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    invoiceIdData();
  }, [id]);

  const invoiceIdData = async () => {
    try {
      const res = await Invoiceid(id);
      if (res.status === 200) {
        console.log(res.data, "invoiceid bhabesh");
        console.log(res.data.data.subscription_id.plan_price);
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const pdf = useRef();



  const downloadInvoice = () => {
    const input = pdf.current;
    console.log(input, 'input--pdf');

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Set custom PDF dimensions
      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = 150 * 2; // Custom height (3 times the A4 height)

      // Initialize jsPDF with the specified dimensions
      const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight]);

      // Calculate the image dimensions and positions
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      const adjustedImgWidth = imgWidth * ratio;
      const adjustedImgHeight = imgHeight * ratio;

      const imgX = (pdfWidth - adjustedImgWidth) / 2; // Center horizontally
      const imgY = 0; // Start at the top

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', imgX, imgY, adjustedImgWidth, adjustedImgHeight);

      // Save the PDF
      pdf.save('invoice.pdf');
    });
  };


  const formatDate = (date) => new Date(date).toLocaleDateString('en-GB');


  const currentDate = formatDate(new Date());
  
  

  return (

    <div className="invoice-container">
    <div>
      <button type="button" className="btn text-white" style={{ background: "#19c3e6" }} onClick={() => downloadInvoice()}>
        DOWNLOAD
      </button>
    </div>

    <div ref={pdf} className="invoice-content">
      <div className="header">
        <div className="logo">
          <h2><span className="brand">Invorail</span></h2>
        </div>
        <div className="order-info">
          <h3>Invoice</h3>
          <p>ORDER #800000025</p>
          <p>{currentDate}</p>
          </div>
      </div>
      <div className="customer-message">
        <p>Hello, <span className="highlight">bhabesh-org</span></p>
        <p>Thank you for shopping from our store and for your order.</p>
      </div>
      <table className="item-table">
        <thead>
          <tr>
            <th>Item</th>
            <th>SKU</th>
            <th>Quantity</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            <tr>
              <td className="highlight">{data.subscription_id.plan_name}</td>
              <td>invorail-0001</td>
              <td>1</td>
              <td>{data.subscription_id.plan_price}</td>
            </tr>
          ) : (
            <tr>
              <td   colSpan="4">Subscription plan details failed</td>
            </tr> 
          )}
        </tbody>
      </table>
      {data && ( 
        <div className="total-info">
          <p>Subtotal:{data.subscription_id.plan_price}</p>
          <p>Shipping & Handling: 15.00</p>
          <p>Grand Total: {data.subscription_id.plan_price}</p>
          {/* <p>TAX: 0</p> */} 
        </div>
      )}
    </div>
  </div>
);
   
};

export default InvoiceDownload;
