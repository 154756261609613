import React, { useState, useEffect } from "react";
import PaymentNav from "./PaymentNav";
import "rc-slider/assets/index.css";
import { getPlan, getActivePlan, getAllSubscriptions, cancelSubscription } from '../../../ApiHandler/apihandler';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import moment from 'moment'
import Swal from "sweetalert2";


// import './premium.css';
import '../Premium/premium.css'



const ChoosePlan = () => {
  const [activePlan, setActivePlan] = useState(null);
  const navigate = useNavigate()

  const [planData, setPlanData] = useState([])
  const [active, setActive] = useState('')
  const [subscriptions, setSubscription] = useState([])
  const [userId, setUserId] = useState('')
  const [matchId, setMatchId] = useState('');
  const [custId, setCustId] = useState('');
  const [subId, setSubId] = useState('');
  const [cancel, setCancel] = useState(true);
  const [status ,setStatus] = useState(true)
  
  useEffect(() => {
    getPlanData()
    // GetActivePlan()
  }, []);
  
  useEffect(()=> {
    GetActivePlan()
    GetAllSubscriptionsplan()
  },[status])

  const cancelsubscription = async (subscriptionId) => {

    try {
      const results = await Swal.fire({
        title: "Are you sure?",
        text: "You want to cancel your subscription!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#19c3e6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel it!"
      });

      if (results.isConfirmed) {
        const response = await cancelSubscription(subscriptionId)

        console.log(response, "response--->>><,,,,....")
        setStatus(false);
        // const result = await response.json();
        if (response.status === 200) {
          Swal.fire({
            title: "Cancelled!",
            text: "Your subscription has been cancelled.",
            icon: "success",
            confirmButtonColor: "#19c3e6"
          });

          console.log('Subscription canceled:', response.deletedSubscription);
          // Handle successful cancellation (e.g., update UI)
        } else {
          console.error('Failed to cancel subscription');
          // Handle failure (e.g., show error message)
        }
      }


    } catch (error) {
      console.error('Error canceling subscription:', error);
    }
  };

  const GetActivePlan = async () => {
    try {
      const user_id = userId;
      const res = await getActivePlan();
      console.log(res);
      const plan = res.data.status;
      console.log(plan, "plan active----"); 
      setActive(res.data.status);
      const custId = res.data.user_id;
      setCustId(custId);
      console.log(custId, "custId---");
      setMatchId(res.data.plan_id);
      setSubId(res.data.subscriptionId)
      console.log(res.data.subscriptionId, "custIdsub---");
      if (!plan) {
        setCancel(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllSubscriptionsplan = async () => {
    try {
      const res = await getAllSubscriptions();
      console.log(res, "all subscriptions -------->");
      setSubscription(res.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const getPlanData = async () => {
    try {
      const data = await getPlan()

      if (data.status === 200) {
        console.log(data.data);
        setPlanData(data.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const steps = [
    { label: "Choose a plan", path: "/choose-plan" },
    // { label: "Customize", path: "/customize" },
    { label: "Checkout", path: "/checkout" },
  ];


  const plans = [
    { title: "Free", price: "₹0", features: ["Up to 300 emails per day", "Customizable email templates", "Drag & drop editor"], buttonText: "Your Plan", isCurrent: true },
    { title: "Starter", price: "₹625", features: ["No daily sending limit", "No Brevo logo", "Reporting and analytics tools"], buttonText: "Upgrade to Starter", isCurrent: false },
    { title: "Business", price: "₹1,345", features: ["Marketing automation", "A/B testing", "Advanced reporting"], buttonText: "Upgrade to Business", isCurrent: false },
    { title: "Enterprise", price: "Custom price", features: ["Flexible contract", "Unlimited contacts", "Advanced integrations"], buttonText: "Upgrade to Enterprise", isCurrent: false },
  ];

  const handleCardClick = (index) => {
    setActivePlan(index);
  };

  return (
    <>
      <div>
        <PaymentNav planData={planData} steps={steps} currentStep={1} />


        {subscriptions.length > 0 ? (
          <>
            <div className="mr-5 ml-5 mt-3">
              <table className="table table-bordered mt-3">
                <thead>
                  <tr className="text-center">
                    <th scope="col">Plan Name</th>
                    <th scope="col">Plan Price</th>
                    <th scope="col">Active/Upgrade</th>
                  </tr>
                </thead>
                <tbody>
                  {planData.map((det, index) => (
                    <tr key={index} className="text-center">
                      <td
                        style={{ fontWeight: "normal", backgroundColor: "white" }}
                      >
                        {det.planName}
                      </td>
                      <td style={{ backgroundColor: "white" }}>
                        {det.planPrice}
                      </td>
                      <td style={{ backgroundColor: "white" }}>
                        {det._id === matchId ? (
                          <>
                            <span className="text-success" >Active</span>

                          </>

                        ) : (
                          <span
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => navigate(`/checkout/${det._id}`)}
                          >
                            Upgrade
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div >
              {cancel ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      marginRight: '120px',
                      // backgroundColor: '#f5c6cb',
                      // color: '#721c24',
                      // borderRadius: '12px',
                      // padding: '5px 5px',
                      fontWeight: 'bold',
                      fontSize: '14px'
                    }}
                    onClick={() => cancelsubscription(subId)}
                  >
                    Cancel Subscription
                  </Button>
                </div>) : ""}


              {/* <div className="d-flex justify-content-between align-items-center"> */}
              <h3 className="text-center mb-0">
                Your subscribed Plans
              </h3>
              {/* <Button variant="outlined" className="mr-5" onClick={handleCancel}>
              Cancel
            </Button> */}
            </div>
            {/* </div> */}


            <div className="mr-5 ml-5 mt-3">
              <table className="table table-bordered mt-3">
                <thead>
                  <tr className="text-center">
                    <th scope="col">Plan Name</th>
                    <th scope="col">Plan Price</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptions.map((det, index) => (
                    <tr
                      key={index}
                      className="text-center"
                      style={{ cursor: "pointer" }}
                    >
                      <td style={{ backgroundColor: "white" }}>{det.plan_name}</td>
                      <td style={{ backgroundColor: "white" }}>{det.plan_price}</td>
                      <td style={{ backgroundColor: "white" }}>
                        {moment(det.subscription_start_date).format("DD-MM-YYYY")}
                      </td>
                      <td style={{ backgroundColor: "white" }}>
                        {moment(det.subscription_end_date).format("DD-MM-YYYY")}
                      </td>
                      <td style={{ backgroundColor: "white" }}>{det.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : <div className="container mt-5">
          <h2 className="text-center mb-4">Upgrade your Marketing Platform</h2>
          {/* <div className="text-center mb-4">
        <button className="btn btn-light me-2">Monthly</button> 
        <button className="btn btn-primary">Yearly (Save 10%)</button>
      </div> */}
          <div className="row justify-content-center mt-5">
            {planData.map((plan, index) => (
              <div className="col-md-3" key={index}>
                <div
                  className={`card ${index === activePlan ? 'border-active' : ''} mb-4`}
                  onClick={() => handleCardClick(index)}
                >
                  <div className="card-body text-center">
                    <h5 className="card-title">{plan.planName}</h5>
                    <p className="card-text">{plan.planDescription}</p>
                    <p className="card-text mt-2">${plan.planPrice}/{plan.planDuration}</p>
                    {/* <ul className="list-unstyled">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="mb-2">{feature}</li>
                  ))}
                </ul> */}
                    {/* <button className="btn btn-dark">{plan.buttonText}</button> */}
                    <Button className="btn btn-primary w-100" style={{ background: "#19c3e6", marginTop: "50px" }} onClick={() => navigate(`/checkout/${plan._id}`)}>Buy Now</Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>}
      </div>
    </>
  );
};

export default ChoosePlan;
