import axios from 'axios';
const auth = localStorage.getItem("auth_token");
const userID = localStorage.getItem('user_id');

const headers = {
  "x-auth-token": auth,
};




export const postProduct = async (data) => {
  return axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/product/addproduct`, data, { headers })
}

export const getProducts = async () => {
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/product/getproduct`, { headers })
}
// 
  export const updateProduct = async (productId, data) => {
    return axios.put(`${process.env.REACT_APP_WEB_URL}api/v1/product/putproduct/${productId}`, data, { headers })
  }
  // 
  export const deleteProduct = async (productId) => {
    return axios.delete(`${process.env.REACT_APP_WEB_URL}api/v1/product/deleteproduct/${productId}`, { headers })
  } 




export const postItemType = async (data) => {
  return axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/product/additemtype`, data, { headers })
}

export const getItemTypes = async () => {
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/product/getitemtypes`, { headers })
}
// 
export const updateItemType = async (ItemTypeId, data) => {
  return axios.put(`${process.env.REACT_APP_WEB_URL}api/v1/product/putItems/${ItemTypeId}`, data, { headers })
}
// 
export const deleteItemType = async (ItemTypeId) => {
  return axios.delete(`${process.env.REACT_APP_WEB_URL}api/v1/product/deleteItems/${ItemTypeId}`, { headers })
}
export const getPlan = async (data) => {
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/plan/getplan`, data, { headers })
}


export const getPlanByid = async (id) => {
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/plan/${id}`, {
    headers: headers
  });
};

// export const createPayment = async (data) => {
//   return axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/payment/create-payment`, data, { headers })
// }

export const createPaymentIntent = async (amount,planName,plan_id,startdate,enddate) => {
  try {
       const response = await fetch(`${process.env.REACT_APP_WEB_URL}api/v1/payment/create-payment`, {
          method: 'POST',
           headers: {
               'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              amount: Math.round(amount * 1), 
              planName:planName,
              // plan_id:plan_id,
              userID:userID,
              // startdate:startdate,
              // enddate:enddate,
               currency: 'usd', 
               description: 'Test Payment',
           }),
      });

      if (!response.ok) {
           throw new Error('Failed to create payment intent');
       }

      return await response.json();
   } catch (error) {
       console.error('Error creating payment intent:', error);
       throw error;
   }
};

export const verifyPayment = async (paymentIntentId, amount, planName, planid, startdate, enddate, userID) => {
  const response = await fetch(`${process.env.REACT_APP_WEB_URL}api/v1/payment/verify-payment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ paymentIntentId, amount, planName, planid, startdate, enddate, userID }),
  });

  const data = await response.json();
  return data;
};

export const getActivePlan = async () => {
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/payment/getactive-plan`, { headers })
}

export const getAllSubscriptions = async()=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/payment/get-allsubcription`,{ headers })
}
export const cancelSubscriptions = async()=>{
  return axios.put(`${process.env.REACT_APP_WEB_URL}api/v1/payment/cancel-subscription`,{},{ headers })
}

export const getSubscriptionByid = async (id) => {
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/payment/subcription/${id}`, {
    headers: headers
  });
};

export const getInvoiceById = async()=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/payment/getinvoice`, {
    headers: headers
  });
}

export const Invoiceid = async (id) => {
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/payment/getinvoice-id/${id}`, {
    headers: headers
  });
};
export const getClient = async () => {
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/client/getclient`, {
    headers: headers
  });
};
export const updateClient = async (clientId, data) => {
  return axios.put(`${process.env.REACT_APP_WEB_URL}api/v1/client/putclient/${clientId}`, data, {
    headers: headers,
  });
};
export const deleteClient = async (id) => {
  return axios.delete(`${process.env.REACT_APP_WEB_URL}api/v1/client/deleteclient?id=${id}`, { headers })
}


//invoice 
export const createInvoice = async(data)=>{
  return axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/invoice/addinvoice`,data, {
    headers: headers
  });
}

export const getAllInvoice = async()=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/invoice/getinvoice`,{ headers })
}



export const deleteInvoice = async (invoiceId) => {
  return axios.delete(`${process.env.REACT_APP_WEB_URL}api/v1/invoice/deleteinvoice/${invoiceId}`, { headers })
} 

export const getInvoiceId = async()=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/invoice/getinvoice`, {
    headers: headers
  });
}


export const  getInvoicebyCustomerID = async(id)=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/invoice/invoicebycustomer/${id}`, {
    headers: headers
  });
}

// Recurring Invoice

export const createRecurringInvoice = async(data)=>{
  return axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice/addrecurringinvoice`,data, {
    headers: headers
  });
}

export const getAllStripeInvoice = async(productId)=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice/product/${productId}`,{ headers })
}

export const sendRecurringMail = async(data) => {
  return axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice/sendrecurringmail`,data, {
    headers: headers 
  });
}

export const sendOTP = async(data) => {
  return axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice/send-otp`,data, {
    headers: headers 
  });
}

export const verifyOTP = async(data) => {
  return axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice/verify-otp`,data, {
    headers: headers 
  });
}

export const cancelReason = async(data) => {
  return axios.post(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice/cancel-reason`,data, {
    headers: headers 
  });
}

export const getAllRecurringInvoice = async()=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice/getrecurringinvoice`,{ headers })
}

export const deleteRecurringInvoice = async (invoiceId) => {
  return axios.delete(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice/deleterecurringinvoice/${invoiceId}`, { headers })
} 

export const getRecurringInvoiceId = async(id)=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice//getrecurringinvoiceid/${id}`, {
    headers: headers
  });
}

export const  getRecurringInvoicebyCustomerID = async(id)=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/recurringinvoice/recurringinvoicebycustomer/${id}`, {
    headers: headers
  });
}



export const cancelSubscription = async (subcriptionid) => {
  return axios.delete(`${process.env.REACT_APP_WEB_URL}api/v1/payment/cancel-subscription/${subcriptionid}`, { headers:headers })
} 

export const cancelRecurringSubscription = async (subcriptionid) => {
  return axios.delete(`${process.env.REACT_APP_WEB_URL}api/v1/recurringpayment/cancel-recurring-subscription/${subcriptionid}`, { headers:headers })
} 

export const  getUserProfile = async()=>{
  return axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/auth/getUser-Profile`, {
    headers: headers
  });
}

export const  updateUser = async(user_id,userData)=>{
  return axios.put(`${process.env.REACT_APP_WEB_URL}api/v1/auth/updateuser/${user_id}`,userData, {
    headers: headers
  });
}

 









