import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./Stripe";
import { useState } from "react";

const stripe = loadStripe('pk_test_51PVdhuLwXNdguAahbR3CdwoeSpZoJDyIJTGPrJpjsRV6TEUTwEpA04OVeo3QUav2v8kx63PFuVYrhgH3wnFpgn9p00Nd1OloQy');
 // starts with pk_

function StripeForm({data}) {
  // const [ planData ,setPlanData]= useState(data);
  console.log(data,'data------>>>---->')
  return (
    <div>
      <Elements stripe={stripe}>
        <CheckoutForm data={data}/>
      </Elements>
      
    </div>
  );
}

export default StripeForm;