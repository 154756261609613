import { TextField, Button, Box } from '@mui/material';
import "../../Style/Invorail.css"
import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { postProduct } from '../../../ApiHandler/apihandler';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export default function ProductPage() {

    const navigate = useNavigate();
    const token = localStorage.getItem('auth_token');

    const [itemType, setItemType] = React.useState([]);
    const [selectType, setselectType] = useState('');
     
    const itemData = {
        name: "",
        type: "",
        description: "",
        quantity: "",
        unitPrice: "",
        totalPrice: "",
        tax: "",   
    }

    const [formdata, setFormData] = useState(itemData);

    useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_WEB_URL}api/v1/product/getitemtypes`, {
                headers: {
                    'x-auth-token': token
                }
            });
            console.log(res, "response----");
            setItemType(res.data.ItemType);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSaveProduct = async () => {
        try {
            console.log(formdata, "formdata--->>");
            const savedproduct = await postProduct(formdata);
            console.log(savedproduct, "savedproduct----");
            if (savedproduct.status === 200) {
                setFormData(itemData);
                navigate(`/products-table`);
                swal("", "Product Added Successfully", "success");
            }
        } catch (error) {
            swal("", error.message, "danger"); 
        }
    };

    const handleDiscard = () => {
        setFormData(itemData);
    };

    useEffect(() => {
        const { unitPrice, quantity, tax } = formdata;
        const totalPrice = (unitPrice * quantity) - ((unitPrice * quantity * tax) / 100);
        setFormData({ ...formdata, totalPrice: totalPrice.toFixed(2) });
    }, [formdata.unitPrice, formdata.quantity, formdata.tax, formdata]);

    return (
        <>
            <div className="container px-4">
                <div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <div><h5 style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>Products/Services</h5></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-6'>
                            <div>
                                <label htmlFor="clientName1" className="form-label">Item Type</label>
                                <TextField
                                    select
                                    size="small"
                                    style={{ background: "#D0F0FF", width: "100%", borderRadius: "5px" }}
                                    className='border border-primary'
                                    onChange={(e) => setFormData({ ...formdata, type: e.target.value })}
                                    value={formdata.type}
                                >
                                    {itemType.map((items) => (
                                        <MenuItem value={items.name} key={items.name}>{items.name}</MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="clientName1" className="form-label">Unit Price</label>
                                <TextField
                                    size="small"
                                    style={{ background: "#D0F0FF", width: "100%", borderRadius: "5px" }}
                                    className='border border-primary'
                                    type='number'
                                    value={formdata.unitPrice}
                                    onChange={(e) => setFormData({ ...formdata, unitPrice: e.target.value })}
                                />
                            </div>

                            <div className='form-group mt-2'>
                                <label htmlFor="clientName3" className="form-label">Tax</label>
                                <TextField
                                    select
                                    size="small"
                                    style={{ background: "#D0F0FF", width: "100%", borderRadius: "5px" }}
                                    className='border border-primary'
                                    value={formdata.tax}
                                    onChange={(e) => setFormData({ ...formdata, tax: e.target.value })}
                                >
                                    <MenuItem value={"5"}>5%</MenuItem>
                                    <MenuItem value={"10"}>10%</MenuItem>
                                    <MenuItem value={"15"}>15%</MenuItem>
                                    <MenuItem value={"20"}>20%</MenuItem>
                                </TextField>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div>
                                <label htmlFor="clientName1" className="form-label">Name</label>
                                <TextField
                                    type='text'
                                    size="small"
                                    style={{ background: "#D0F0FF", width: "100%", borderRadius: "5px" }}
                                    className='border border-primary'
                                    value={formdata.name}
                                    onChange={(e) => setFormData({ ...formdata, name: e.target.value })}
                                />
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="clientName1" className="form-label ">Quantity</label>
                                <TextField
                                    type='number'
                                    size="small"
                                    style={{ background: "#D0F0FF", width: "100%", borderRadius: "5px" }}
                                    className='border border-primary'
                                    value={formdata.quantity}
                                    onChange={(e) => setFormData({ ...formdata, quantity: e.target.value })}
                                />
                            </div>

                            <div className='form-group mt-2'>
                                <label htmlFor="type2" className="form-label">Total Amount</label>
                                <TextField
                                    type='number'
                                    size="small"
                                    style={{ background: "#D0F0FF", width: "100%", borderRadius: "5px" }}
                                    className='border border-primary'
                                    value={formdata.totalPrice}
                                    onChange={(e) => setFormData({ ...formdata, totalPrice: e.target.value })}
                                    disabled
                                    InputProps={{
                                        style: { color: 'black' },
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-md-12 mt-2'>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Description</label>
                                <textarea className="form-control mt-2 border border-primary" style={{ background: "#D0F0FF", borderRadius: "5px" }} id="exampleFormControlTextarea1" rows="3" value={formdata.description} onChange={(e) => setFormData({ ...formdata, description: e.target.value })}></textarea>
                            </div>

                            <div className='d-flex justify-content-between mt-4'>
                                <div>
                                    <span>
                                        <button type="button" className="btn border border-primary" onClick={() => navigate(`/products-table`)}>Preview</button>
                                    </span>
                                </div>

                                <div>
                                    <span>
                                        <button type="button" className="btn border border-primary" onClick={handleDiscard}>Discard</button>
                                    </span>

                                    <span style={{ marginLeft: "30px" }}>
                                        <button type="button" className="btn ml-2 text-white" style={{ background: "#19c3e6" }} onClick={() => handleSaveProduct()}>Save</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
