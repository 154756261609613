import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import PaymentForm from "./PaymentForm";
import { createPaymentIntent } from "../../../ApiHandler/apihandler";

const stripe = loadStripe('pk_test_51PVdhuLwXNdguAahbR3CdwoeSpZoJDyIJTGPrJpjsRV6TEUTwEpA04OVeo3QUav2v8kx63PFuVYrhgH3wnFpgn9p00Nd1OloQy');

const StripePayment = () => {
  	const [clientSecret, setClientSecret] = useState(null);
    const state = {
        amount:'500',
        planName:'Diamond',
        planid:"456789456789",
        startdate:'20/4/2024',
        enddate:'21/2/2024'
    
      }
      const userID = localStorage.getItem('user_id');
      const [amount, setAmount] = useState(state.amount);
      const [planName, setPlanName] = useState(state.planName);
      const [planid, setPlanid] = useState(state.planid);
      const [startdate, setStartdate] = useState(state.startdate);
      const [enddate, setEnddate] = useState(state.enddate);
    

	// useEffect(() => {
	// 	axios
	// 		.post("http://localhost:4000/create-payment-intent", {
	// 			items: [{ id: 1, name: "momos", amount: 120.00 }],
	// 		})
	// 		.then((resp) => setClientSecret(resp.data.clientSecret));
	// }, []);


    useEffect( ()=>{
      
        const fetchdata = async() =>{
        const  client  =  await createPaymentIntent(amount,planName,userID);
        console.log(client.clientSecret,"client-----")
        setClientSecret(client.clientSecret)
        } 
        fetchdata();
    },[])

	const options = {
		clientSecret,
		theme: "stripe",
	};

	return (
		clientSecret && (
			<Elements stripe={stripe} options={options}>
				<PaymentForm/>
			</Elements>
		)
	);
};

export default StripePayment;

