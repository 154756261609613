import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from 'moment';
import profile from "../.././../Images/ViratKohli.png";
import axios from "axios";
import { getInvoicebyCustomerID } from "../../../ApiHandler/apihandler";

const ClientDetails = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [client, setClient] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    clientdetails();
    clientInvoice();
  }, []);

  const clientdetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_WEB_URL}api/v1/client/getclient/${id}`
      );
      if (response.status === 200) {
        setClient(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching client details:", error);
    }
  };

  const clientInvoice = async () => {
    try {
      const data = await getInvoicebyCustomerID(id);
      setInvoiceData(data.data); 
    } catch (error) {
      console.error("Error fetching customer invoice data:", error);
    }
  };

  return (
    <Box className="d-flex px-3 flex-column align-items-center">
      <Box className="w-100">
        <Card sx={{ boxShadow: 3 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                {client && (
                  <Box className="mt-5 d-flex flex-column align-items-center text-center">
                    <img
                      src={profile}
                      alt="Profile"
                      style={{
                        height: "160px",
                        width: "160px",
                        borderRadius: "75px",
                        objectFit: "cover",
                      }}
                    />
                    <Typography variant="h5" sx={{ margin: "10px 0 5px 0" }}>
                      {client.first_name}
                    </Typography>
                    <Typography variant="body1" sx={{ margin: "0 0 15px 0" }}>
                      {client.company}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => alert("Button clicked")}
                    >
                      Profile
                    </Button>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={8}>
                {client && (
                  <>
                    <Box className="px-2 mt-2">
                      <Typography variant="h5">Official Information</Typography>
                      <Grid container spacing={2} className="mt-3 p-1">
                        <Grid item xs={12} md={6}>
                          <Box sx={{ mb: 1 }}>
                            <Typography variant="subtitle1">Name:</Typography>
                            <Typography variant="h6" gutterBottom>
                              {client.billing_addresses[0].first_name}{" "}
                              {client.billing_addresses[0].last_name}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box sx={{ mb: 1 }}>
                            <Typography variant="subtitle1">Email:</Typography>
                            <Typography>{client.email}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box sx={{ mb: 1 }}>
                            <Typography variant="subtitle1">Company:</Typography>
                            <Typography>{client.company}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box sx={{ mb: 1 }}>
                            <Typography variant="subtitle1">Business Type:</Typography>
                            <Typography>{client.business_type}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1">Phone Number:</Typography>
                            <Typography>{client.phone}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <hr style={{ border: "1px solid gray", margin: "auto" }} />
                    <Box className="px-2 mt-2">
                      <Typography variant="h5">Personal Information</Typography>
                      <Grid container spacing={2} className="mt-3">
                        <Grid item xs={12} md={6}>
                          <Box sx={{ mb: 1 }}>
                            <Typography variant="subtitle1">Email:</Typography>
                            <Typography>{client.email}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box sx={{ mb: 1 }}>
                            <Typography variant="subtitle1">Phone Number:</Typography>
                            <Typography>{client.phone}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1">Address:</Typography>
                            <Typography>
                              {client.billing_addresses[0].line_1}
                              {client.billing_addresses[0].line_2 && (
                                <span>
                                  , {client.billing_addresses[0].line_2},
                                </span>
                              )}
                              <br />
                              <span> {client.billing_addresses[0].city}</span>
                              <span> {client.billing_addresses[0].state}</span>
                              <span>
                                {" "}
                                {client.billing_addresses[0].zipcode}
                              </span>
                              <span>
                                {" "}
                                {client.billing_addresses[0].country}
                              </span>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <Box sx={{ p: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowTable(!showTable)}
              sx={{ mb: 2 }}
            >
              {showTable ? "Show Invoices" : "Show Invoices"}
            </Button>
            <Box
              sx={{
                maxHeight: showTable ? "500px" : "0px",
                opacity: showTable ? 1 : 0,
                overflow: "hidden",
                transition: "max-height 0.3s ease-out, opacity 0.3s ease-out",
              }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Invoice No</TableCell>
                      <TableCell align="left">Item</TableCell>
                      <TableCell align="left">Item Price</TableCell>
                      <TableCell align="left">Tax</TableCell>
                      <TableCell align="left">Total Price</TableCell>
                      <TableCell align="left">Invoice Date</TableCell>
                      <TableCell align="left">Due Date</TableCell>
                      <TableCell align="left">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData && invoiceData.length > 0 ? (
                      invoiceData.map((invoice, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">INV000{index + 1}</TableCell>
                          <TableCell align="left">{invoice.invoice_product_items[0].itemName}</TableCell>
                          <TableCell align="left">{invoice.invoice_product_items[0].amount}</TableCell>
                          <TableCell align="left">{invoice.taxAmount}</TableCell>
                          <TableCell align="left">{invoice.totalAmount}</TableCell>
                          <TableCell align="left">
                            {moment(invoice.invoice_date).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell align="left"> 
                            {moment(invoice.due_date).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell align="left">{invoice.payment_status}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No invoices found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ClientDetails;
