import React, { useEffect, useState } from 'react';
import { MDBCardText } from 'mdb-react-ui-kit';
import Button from '@mui/material/Button';
import { getPlanByid } from '../../../ApiHandler/apihandler';
import { useNavigate, useParams } from 'react-router-dom';

export default function SubcribePlan() {
    
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);

    useEffect(() => {
        getPlanData();
    }, [id]);


    const getPlanData = async () => {
        try {
            const response = await getPlanByid(id);
            if (response.status === 200) {
                console.log(response.data);
                setData(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="container d-flex justify-content-center vh-100">
            <div className="row">
                <div className="col-md-4 mt-4">
                    {data ? (
                        <div className="card text-center" style={{ width: '25rem' }}>
                            <div className="card-body" style={{ height: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <h5 className="card-title mt-2">{data.planName}</h5>
                                <p className="card-text mt-2">Description: {data.planDescription}</p>   
                                <p className="card-text mt-2">${data.planPrice}/{data.planDuration}</p>
                                <Button
                                    className="btn btn-primary"
                                    style={{ background: "#19c3e6", marginTop: "50px" }}
                                    onClick={() => navigate(`/stripe-payment/${data._id}`, { state: { amount: data.planPrice,planName:data.planName,planid:data._id,startdate:data.start_date,enddate:data.end_date}})}> 
                                    Subscribe
                                </Button>  
                            </div> 
                        </div>
                    ) : (
                        <MDBCardText>Loading...</MDBCardText>
                    )} 
                </div>
            </div>
        </div>
    );
}
