import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardHeader,
} from "mdb-react-ui-kit";
import Button from "@mui/material/Button";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { Typography } from "@mui/material";
import Swal from "sweetalert2";
import {
  getPlan,
  getActivePlan,
  getAllSubscriptions,
  cancelSubscriptions
} from "../../../ApiHandler/apihandler";
import { useNavigate } from "react-router-dom";
import "./premium.css";

export default function PremiumCard() {
  const navigate = useNavigate();
  const [planData, setPlanData] = useState([]);
  const [active, setActive] = useState("");
  const [subscriptions, setSubscription] = useState([]);
  const [userId, setUserId] = useState("");
  const [matchId, setMatchId] = useState("");
  const [custId, setCustId] = useState("");

  useEffect(() => {
    getPlanData();
    GetActivePlan(); 
    GetAllSubscriptionsplan(); 
  }, []);   
       
  const GetActivePlan = async () => {
    try { 
      const user_id = userId;
      const res = await getActivePlan(); 
      console.log(res);
      const plan = res.data.status;
      console.log(plan, "plan active----");
      setActive(res.data.status);
      const custId = res.data.user_id;
      setCustId(custId); 
      console.log(custId, "custId---");
      setMatchId(res.data.plan_id);
    } catch (error) {
      console.log(error);
    } 
  };  

  const GetAllSubscriptionsplan = async () => {
    try {  
      const res = await getAllSubscriptions();
      console.log(res, "all subscriptions -------->");
      setSubscription(res.data); 
    } catch (error) { 
      console.error("Error fetching subscriptions:", error);
    }
  };  

  const getPlanData = async () => {
    try {
      const data = await getPlan();
      if (data.status === 200) {
        console.log(data.data);
        setPlanData(data.data.data);
      } 
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to cancel your subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {   
         
          await cancelSubscriptions();
          Swal.fire("Cancelled!", "Your subscription has been cancelled.", "success");
          // Redirect to the login page
          navigate("/login");
        } catch (error) {
          console.error("Error cancelling subscription:", error);
          Swal.fire("Error", "There was an issue cancelling your subscription.", "error");
        }
      }
    });
  };
  

  return (
    <>
      <div>
        <Typography
          variant="h4"
          sx={{ color: "#03565A", textAlign: "center", margin: "30px" }}
        >
          Choose Your Plan
        </Typography>
      </div>

      {subscriptions.length > 0 ? (
        <>
          <div className="mr-5 ml-5 mt-3">
            <table className="table table-bordered mt-3">
              <thead>
                <tr className="text-center">
                  <th scope="col">Plan Name</th>
                  <th scope="col">Plan Price</th>
                  <th scope="col">Active/Upgrade</th>
                </tr>
              </thead>
              <tbody>
                {planData.map((det, index) => (
                  <tr key={index} className="text-center">
                    <td
                      style={{ fontWeight: "normal", backgroundColor: "white" }}
                    >
                      {det.planName}
                    </td>
                    <td style={{ backgroundColor: "white" }}>
                      {det.planPrice}
                    </td>
                    <td style={{ backgroundColor: "white" }}>
                      {det._id === matchId ? (
                        <span className="text-success">Active</span>
                      ) : ( 
                        <span
                          style={{ cursor: "pointer", color: "red" }}
                          onClick={() => navigate(`/subscribeplan/${det._id}`)}
                        >
                          Upgrade
                        </span>
                      )}
                    </td>   
                  </tr>    
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <h3 className="text-center flex-grow-1 mb-0">
              Your subscribed Plans
            </h3>
            <Button variant="outlined" className="mr-5" onClick={handleCancel}>
              Cancel
            </Button>
          </div>

          <div className="mr-5 ml-5 mt-3">
            <table className="table table-bordered mt-3">
              <thead>
                <tr className="text-center">
                  <th scope="col">Plan Name</th>
                  <th scope="col">Plan Price</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((det, index) => (
                  <tr
                    key={index}
                    className="text-center"
                    style={{ cursor: "pointer" }}
                  > 
                    <td style={{ backgroundColor: "white" }}>{det.planName}</td>
                    <td style={{ backgroundColor: "white" }}>{det.amount}</td>
                    <td style={{ backgroundColor: "white" }}> 
                      {moment(det.subscription_start_date).format("DD-MM-YYYY")}
                    </td>
                    <td style={{ backgroundColor: "white" }}>
                      {moment(det.subscription_end_date).format("DD-MM-YYYY")}
                    </td>
                    <td style={{ backgroundColor: "white" }}>{det.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="container   vh-100">
          <div className="row">
            {planData.map((row) => (
              <div className="col-md-4 mt-4">
                <div className="card text-center " style={{ width: "20rem" }}>
                  <div
                    className="card-body "
                    style={{
                      height: "300px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <h5 className="card-title mt-2">{row.planName}</h5>
                    <p className="card-text mt-2">
                      Description:{row.planDescription}
                    </p>
                    <p className="card-text mt-2">
                      ${row.planPrice}/{row.planDuration}
                    </p>
                    <Button
                      className="btn btn-primary"
                      style={{ background: "#19c3e6", marginTop: "50px" }}
                      onClick={() => navigate(`/subcribeplan/${row._id}`)}
                    >
                      Buy Now
                    </Button> 
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
