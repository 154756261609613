import React, { useState, useEffect } from 'react';
import { getUserProfile, updateUser } from '../../ApiHandler/apihandler';
import Swal from 'sweetalert2';
import { Modal, Button, Form } from 'react-bootstrap';

const SettingProfileInfo = () => {
    const [profile, setProfile] = useState(null);
    const [editableProfile, setEditableProfile] = useState({
        name: '',
        company: '',
        email: '',
        _id: '',
    });
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        userProfile(); 
    }, []);

    const userProfile = async () => {
        try {
            const data = await getUserProfile();
            setProfile(data.data.user);
            setEditableProfile({
                name: data.data.user.name,
                company: data.data.user.company,
                email: data.data.user.email,
                _id: data.data.user._id
            });
        } catch (error) {
            console.error("Error fetching user profile data:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditableProfile(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = async () => {
        try {
            const updatedData = await updateUser(editableProfile._id, editableProfile);
            // setProfile(updatedData.data.user); // Update the profile state with the new data
            setShowModal(false); // Close the modal after saving
            Swal.fire({
                title: 'Success!',
                text: 'Profile updated successfully.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            userProfile();
        } catch (error) {
            console.error("Error updating user profile data:", error);
            Swal.fire({
                title: 'Error!',
                text: 'There was an issue updating the profile. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <div>
            <div className="container px-4">
                <div className="mt-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="fw-bold" style={{ fontFamily: "sans-serif" }}>Profile Info</h5>
                        <button 
                            type="button" 
                            className="btn text-white border border-primary" 
                            style={{ background: "#19c3e6" }}
                            onClick={() => setShowModal(true)} // Show the modal when button is clicked
                        >
                            UPDATE PROFILE +
                        </button>
                    </div>

                    <div className="row mt-4">
                        {profile && (
                            <div key={profile._id}>
                                <div className="form-group mb-3">
                                    <label htmlFor="clientName1" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control border border-primary"
                                        id="clientName1"
                                        style={{ background: "#D0F0FF" }}
                                        value={profile.name}
                                        readOnly
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="clientName3" className="form-label">Company</label>
                                    <input
                                        type="text"
                                        className="form-control border border-primary"
                                        id="clientName3"
                                        style={{ background: "#D0F0FF" }}
                                        value={profile.company}
                                        readOnly
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="type1" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control border border-primary"
                                        id="type1"
                                        style={{ background: "#D0F0FF" }}
                                        value={profile.email}
                                        readOnly
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Modal for updating profile */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={editableProfile.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Company</Form.Label>
                            <Form.Control
                                type="text"
                                name="company"
                                value={editableProfile.company}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={editableProfile.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SettingProfileInfo;
