import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Navbar.css";

const PaymentNav = ({ steps = [], currentStep = 1, planData = [] }) => {
  return (
    <nav className="payment-nav">
      <div className="nav-items">
        <div className="nav-steps">
          {steps.map((step, index) => {
            // Retrieve the id from planData based on the current step
            const planId = planData[index]?._id || ''; 

            return (
              <Link
                key={index}
                to={`${step.path}/${planId}`}
                className={`nav-step ${
                  index + 1 === currentStep
                    ? 'active'
                    : index + 1 < currentStep
                    ? 'completed'
                    : 'inactive'
                }`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
               <span className="step-icon">
                {index + 1 < currentStep ? (     
                  <i className="icon-check">&#10003;</i> // Unicode for checkmark 
                ) : (
                  <span className="step-number">{index + 1}</span>
                )}
              </span>
              <span>{step.label}</span>
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default PaymentNav;
